export function calculateEMA(values, period) {
    let emaArray = new Array(period - 1).fill(null);
    let multiplier = 2 / (period + 1);

    // Calculate the initial SMA
    let sum = 0;
    for (let i = 0; i < period; i++) {
        sum += values[i];
    }
    let sma = sum / period;
    emaArray.push(sma);

    // Calculate the EMA for the rest of the data
    for (let i = period; i < values.length; i++) {
        let ema = (values[i] - emaArray[emaArray.length - 1]) * multiplier + emaArray[emaArray.length - 1];
        emaArray.push(ema);
    }

    return emaArray;
}
