import React from "react";
import { Box, Button, CircleBadge } from "@primer/react";
import { Logo } from "./Branding";

const Suggestions = ({ suggestions, onSuggestionClick }) => (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      mb: 4,
      justifyContent: "flex-end",
      height: "-webkit-fill-available",
    }}>
      <CircleBadge size={72}>
        <Logo size={48} sx={{ color: "colors.fg.default" }} />
      </CircleBadge>
      <Box as="h2">How can I help you today?</Box>
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
          flex: ["1 1 100%", "1 1 45%", "1 1 30%"], // Mobile, Tablet, Desktop
          p: 2,
        },
      }}>
        {suggestions.map((suggestion) => (
          <Button
            key={suggestion}
            variant="invisible"
            onClick={() => onSuggestionClick(suggestion)}
            size="large"
          >
            {suggestion}
          </Button>
        ))}
      </Box>
    </Box>
);
  
export default Suggestions;
  