import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Text } from '@primer/react';
import { RelativeTime } from '@primer/react';
import { ClockIcon } from '@primer/octicons-react';

const OuraLatestActivity = ({ session }) => {
    const [ouraSleepData, setOuraSleepData] = useState(null);
    useEffect(() => {
        const { user } = session;
        const fetchOuraSleepData = async () => {
            const { data, error } = await supabase
                .from('oura_sleep_data')
                .select('*')
                .eq('user_id', user.id)
                .eq('type','long_sleep')
                .order('bedtime_end', { ascending: false })
                .limit(1);
            if (error) {
                console.log(error);
            } else {
                setOuraSleepData(data[0]);
            }
        };

        fetchOuraSleepData();
    }, [session]);

    if (!ouraSleepData) {
        return (
            <Box sx={{
                color: 'fg.subtle',
                fontSize: 1,
                mt: 3,
                ml: 1,
                display: 'flex',
                gap: 1,
                alignItems: 'center',
            }}>
                <Text>Waiting for sleep data</Text>
            </Box>
        );
    }

    const { bedtime_end } = ouraSleepData;

    return (
        <Box sx={{
            color: 'fg.subtle',
            fontSize: 1,
            mt: 3,
            ml: 1,
            display: 'flex',
            gap: 1,
            alignItems: 'center',
        }}>
            
            <Text>Sleep data updated</Text>
            <RelativeTime date={new Date(bedtime_end)} />
        </Box>
    );
};

export default OuraLatestActivity;