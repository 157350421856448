import React from 'react';
import { Box } from '@primer/react';
import PricingTableColumn from './PricingTableColumn';
import { Helmet } from 'react-helmet';

const PricingTable = ({ products, session }) => {
    const jsonLd = {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": "ringsync Subscriptions",
        "description": "Add your Oura data automatically to your Strava activity description. Free forever plan available.",
        "image": "https://api.ringsync.io/storage/v1/object/public/assets/ringsync_wordmark_square_black.png",
        "offers": products.map(product => {
            let unitCode;
            if (product.price.interval) {
                unitCode = product.price.interval === 'month' ? 'MON' : 'ANN';
            }

            const priceSpecification = {
                "@type": "UnitPriceSpecification",
                "priceCurrency": product.price.currency === '€' ? 'EUR' : 'USD',
                "price": product.price.amount,
                "unitCode": unitCode
            };

            return {
                "@type": "Offer",
                "priceSpecification": priceSpecification,
                "itemOffered": {
                    "@type": "Service",
                    "name": product.title,
                    "description": product.description,
                },
                "url": product.button.link,
                "availability": "http://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "ringsync"
                }
            }
        })
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(jsonLd)}
                </script>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: ['column','column', 'column', 'row'], justifyContent: 'center', alignItems: ['center','center','center','stretch'], gap: 3 }}>
                {products.map((product, index) => (
                    session ? product.upgrade && <PricingTableColumn key={index} {...product} session={session} /> : <PricingTableColumn key={index} {...product} session={session} />
                ))}
            </Box>
        </>
    );
};

export default PricingTable;
