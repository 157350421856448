export const ouraColorScheme = {
    light: '#F6F3EF',
    dark:  '#2e2c2a',
    dark_dimmed: '#2e2c2a',
};
  
export const stravaColorScheme = {
    light: '#fc5200',
    dark:  '#652100', //'#E6DED3',
    dark_dimmed: '#652100',
};

export const corporateSandColorScheme = {
    light: '#f3f1f0',
    dark: '#616060',
    dark_dimmed: '#616060'
}