import * as React from "react"
const StravaPremiumBadge = (props) => (
  <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    width={props.size || 24}
    height={props.size || 24}
    fill="currentColor"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FEFEFE"
        d="M.844.999C.584.561.894 0 1.394 0h14.721a.32.32 0 0 1 .275.159l7.021 11.842-7.021 11.842a.324.324 0 0 1-.275.159H1.395c-.502 0-.81-.561-.551-1L7.365 12 .844.999z"
      />
      <path
        fill="#FC4C02"
        d="m21.772 12-6.17-10.179a.67.67 0 0 0-.57-.321H3.272c-.26 0-.42.284-.285.507L9.044 12h12.728z"
      />
      <path
        fill="#CF4017"
        d="M2.987 21.994 9.044 12h12.728l-6.17 10.179a.67.67 0 0 1-.57.32H3.272a.334.334 0 0 1-.285-.506"
      />
    </g>
  </svg>
)
export default StravaPremiumBadge
