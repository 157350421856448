import React from "react";
import { Box } from "@primer/react";

export const OuraIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 124}
    height={props.size || 124}
    viewBox="0 0 133 133"
  >
    <rect width="100%" height="100%" fill={props.fill || "#000"} />
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M66.48 42.976c16.561.018 29.982 13.449 30 30.022 0 16.58-13.431 30.022-30 30.022-16.569 0-30-13.441-30-30.022 0-16.58 13.431-30.022 30-30.022Zm0 6.477c-12.994 0-23.528 10.541-23.528 23.545 0 13.004 10.534 23.545 23.528 23.545 12.988-.014 23.513-10.547 23.528-23.545 0-13.004-10.534-23.545-23.528-23.545Zm15-19.433v6.477h-30V30.02h30Z"
    />
  </svg>
);

export const StravaIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Strava"
    viewBox="0 0 512 512"
    width={props.size || 124}
    height={props.size || 124}
    {...props}
  >
    <rect width={512} height={512} fill="#fc4c01" rx="15%" />
    <path fill="#fff" d="M120 288 232 56l112 232h-72l-40-96-40 96z" />
    <path fill="#fda580" d="m280 288 32 72 32-72h48l-80 168-80-168z" />
  </svg>
);

export const StravaActivityIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 54 54"
      {...otherProps}
      sx={sx}
    >
      <path d="m43.6 27.5-5.3-14.8c-.1-.2-.3-.4-.5-.4s-.4.1-.5.4L28 39.5l-7-18.1c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3l-5.5 14.4-3.3-7.9c-.1-.2-.3-.3-.5-.3H5.3v1.1h5.1l3.7 8.9c.1.2.3.3.5.3s.4-.1.5-.3l5.5-14.4 7.1 18.2c.1.2.3.3.5.3s.4-.1.5-.4L38 14.6l4.9 13.7c.1.2.3.4.5.4h5.4v-1.1h-5.2z" />
    </Box>
  );
};

export const StravaBikeIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 54 54"
      {...otherProps}
      sx={sx}
    >
      <path d="M41.6 42.7c-5.1 0-9.2-4.1-9.2-9.2 0-3.4 1.9-6.4 4.7-8l3.5 8.2c.1.2.3.3.5.3h.2c.3-.1.4-.4.3-.7L38.1 25c1.1-.5 2.3-.7 3.5-.7 5.1 0 9.2 4.1 9.2 9.2s-4.1 9.2-9.2 9.2m-19-9.7c-.2-3.6-2.3-6.8-5.3-8.5l2.3-4.8L26.1 33h-3.5zm-5.7-7.5c2.7 1.5 4.5 4.3 4.7 7.5h-8.3l3.6-7.5zm-4.5 17.2c-5.1 0-9.2-4.1-9.2-9.2s4.1-9.2 9.2-9.2c1.3 0 2.4.3 3.5.7l-4 8.3c-.1.2-.1.4 0 .5.1.2.3.3.5.3h9.2c-.3 4.8-4.3 8.6-9.2 8.6m21.7-23.8L27 32.3l-6.6-13.4h13.7zm7.5 4.3c-1.4 0-2.7.3-4 .8l-4.4-10.5h5.4c.7 0 1.4.6 1.4 1.4 0 .7-.6 1.4-1.4 1.4-.3 0-.5.2-.5.5s.2.5.5.5c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4h-6.2c-.2 0-.4.1-.5.2-.1.2-.1.3 0 .5l2 4.7h-14l-3.2-6.5H20c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-7.6c-.3 0-.5.2-.5.5s.2.5.5.5h3.2l3.5 7.2-2.7 5.4c-1.2-.5-2.6-.8-4-.8-5.7 0-10.3 4.6-10.3 10.3 0 5.7 4.6 10.3 10.3 10.3 5.5 0 10-4.3 10.3-9.8H27c.1 0 .2 0 .3-.1l.1-.1.1-.1 7.3-13.8 1.9 4.5c-3.2 1.8-5.4 5.1-5.4 9 0 5.7 4.6 10.3 10.3 10.3s10.3-4.6 10.3-10.3c0-5.6-4.6-10.2-10.3-10.2" />
    </Box>
  );
};

export const StravaEBikeIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 54 54"
      {...otherProps}
      sx={sx}
    >
      <path d="M40.5 47c-4.7 0-8.5-3.8-8.5-8.5 0-3.2 1.8-6 4.3-7.4l3.2 7.6c.1.2.3.3.5.3h.2c.3-.1.4-.4.3-.7l-3.2-7.7c1-.4 2.1-.7 3.3-.7 4.7 0 8.5 3.8 8.5 8.5-.1 4.8-3.9 8.6-8.6 8.6M23 38c-.2-3.4-2.1-6.3-4.9-7.8l2.1-4.4 6 12.2H23zm-5.3-6.9c2.5 1.4 4.1 3.9 4.3 6.9h-7.7l3.4-6.9zM13.5 47C8.8 47 5 43.2 5 38.5S8.8 30 13.5 30c1.2 0 2.3.2 3.3.7l-3.7 7.6c-.1.2-.1.3 0 .5.1.1.3.2.4.2H22c-.3 4.4-4 8-8.5 8m20.1-22L27 37.4 21 25h12.6zm6.9 4c-1.3 0-2.5.3-3.7.7l-4-9.7h5c.7 0 1.2.6 1.2 1.2 0 .7-.6 1.2-1.2 1.2-.3 0-.5.2-.5.5s.2.5.5.5c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2H32c-.2 0-.3.1-.4.2-.1.1-.1.3 0 .5l1.8 4.3H20.5l-2.9-6h2.9c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-7c-.3 0-.5.2-.5.5s.2.5.5.5h2.9l3.2 6.6-2.5 5.1c-1.1-.5-2.4-.7-3.7-.7C8.3 29 4 33.3 4 38.5S8.3 48 13.5 48c5.1 0 9.2-4 9.5-9h4c.1 0 .2 0 .2-.1l.1-.1.1-.1L34.2 26l1.8 4.2c-3 1.6-5 4.7-5 8.3 0 5.2 4.3 9.5 9.5 9.5s9.5-4.3 9.5-9.5-4.3-9.5-9.5-9.5M43.8 22.6l5-9.7H45V6l-4.9 9.6h3.7z" />
    </Box>
  );
};

export const StravaRunIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 24 24"
      {...otherProps}
      sx={sx}
    >
      <path d="M21.3 18.12 14.98 6.28a2.6 2.6 0 0 0-4.63.07l-.46.93a.585.585 0 0 1-.21-.45V3.17A2.452 2.452 0 0 0 7.24.72a2.172 2.172 0 0 0-2.01 1.4L2.91 6.84 1.39 7.96a2.768 2.768 0 0 0-1.06 2.06 2.96 2.96 0 0 0 .9 2.32l7.76 7.9a11.62 11.62 0 0 0 8.22 3.43h3.65a2.757 2.757 0 0 0 2.41-1.4l.05-.09a2.7 2.7 0 0 0-.01-2.73 2.665 2.665 0 0 0-2.01-1.33zm.85 3.39-.05.09a1.425 1.425 0 0 1-1.24.73h-3.65a10.257 10.257 0 0 1-7.26-3.04l-7.78-7.92a1.566 1.566 0 0 1-.49-1.27 1.426 1.426 0 0 1 .5-1.05l.71-.53 8.6 8.48h1.64v-.28L3.98 7.7l2.48-5.02a.848.848 0 0 1 .78-.61 1.1 1.1 0 0 1 1.09 1.1v3.66a1.92 1.92 0 0 0 1.92 1.92h.43l.88-1.8a1.24 1.24 0 0 1 1.12-.7 1.257 1.257 0 0 1 1.11.67l1.04 1.94L12.69 10v1.52l2.77-1.47.77 1.42v.01l-2.63 1.39v1.53l3.26-1.73.74 1.37-3.02 1.6v1.53l3.65-1.94 2.06 3.85.25.36h.4a1.376 1.376 0 0 1 1.2.69 1.34 1.34 0 0 1 .01 1.38z" />
    </Box>
  );
};

export const StravaWalkIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 54 54"
      {...otherProps}
      sx={sx}
    >
      <path d="M8.33 17.33H5.67v1.34h2.66zm4 0H9.67v1.34h2.66zm4 0h-2.66v1.34h2.66z" />
      <path d="M20.76 11.33h-2.01l-6.89-3.67-1.22-2.29a1.978 1.978 0 0 0-3.72.93v.95l-1 1.88a.425.425 0 0 1-.71.01l-.4-.72A2.023 2.023 0 0 0 3 7.33H1.33v7.1l-.31.29a2.32 2.32 0 0 0-.69 1.67 2.279 2.279 0 0 0 2.28 2.28h1.72v-1.34H2.61a.94.94 0 0 1-.94-.94.973.973 0 0 1 .29-.7l.04-.04v.02h10.33v-1.34H2.67V8.67H3a.7.7 0 0 1 .62.39l.42.75a1.755 1.755 0 0 0 3.07-.05l1.16-2.17V6.3a.64.64 0 0 1 .63-.63.622.622 0 0 1 .55.34l1.41 2.65.69.37-.78 1.46 1.2.64.78-1.47 1.29.69-.78 1.47 1.19.64.78-1.48 1.28.68-.78 1.48 1.19.64.79-1.48.7.37h2.35a1.579 1.579 0 0 1 1.57 1.57 1.664 1.664 0 0 1-.49 1.18 6.742 6.742 0 0 1-4.17 1.88v1.34a8.093 8.093 0 0 0 5.12-2.26 3.002 3.002 0 0 0 .66-3.254 2.905 2.905 0 0 0-2.69-1.796z" />
    </Box>
  );
};

export const StravaHikeIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 54 54"
      {...otherProps}
      sx={sx}
    >
      <path d="m8.35 20.27.25-.25a.815.815 0 0 1 1.14 0l.26.25a3.208 3.208 0 0 0 2.28.95h3.02l-.98-1.35h-2.04c-.499 0-.977-.198-1.33-.55l-.25-.25a2.166 2.166 0 0 0-2.353-.467c-.26.108-.498.267-.697.467l-.25.24a1.876 1.876 0 0 1-1.34.56h-.48l-.97 1.35h1.45a3.273 3.273 0 0 0 2.29-.95z" />
      <path d="M2.94 21.21h.01l.97-1.34h-.81a1.494 1.494 0 0 1-1.49-1.49c0-.572.04-1.144.12-1.71h10.23v-.05l-.93-1.29H1.99l.45-2.27a2.695 2.695 0 0 1 1.32-1.83l6.35-3.55V6.14s-7.07 3.95-7.1 3.97l.19-.98A2.4 2.4 0 0 1 4.39 7.5l3.3-1.83.59-1.16a.988.988 0 0 1 .89-.55 2.123 2.123 0 0 1 2.02 1.47l.03.09c.054.185.081.377.08.57v6.33l1.35.71v-2.39c.102.081.213.152.33.21l1.33.71v2.36l1.35.71v-2.36l1.67.89v2.36l1.34.71v-2.36l1.31.7h1.6a.755.755 0 0 1 .75.75 4.453 4.453 0 0 1-4.45 4.45h-1.9l.95 1.31-.06.04h1.01a5.8 5.8 0 0 0 5.79-5.8 2.1 2.1 0 0 0-2.09-2.09h-1.26l-6.7-3.57a1.83 1.83 0 0 1-.97-1.62V6.09a3.483 3.483 0 0 0-3.48-3.48A2.32 2.32 0 0 0 7.08 3.9l-.4.79-2.95 1.63a3.71 3.71 0 0 0-1.85 2.55L.51 15.89c-.16.82-.24 1.654-.24 2.49a2.837 2.837 0 0 0 2.67 2.83z" />
    </Box>
  );
};

export const StravaSwimIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 54 54"
      {...otherProps}
      sx={sx}
    >
      <path d="M50.5 43.5c-1.2.9-2.7 1.3-4.1 1.3-3.1 0-6-2.4-7.3-6.1-.1-.4-.9-.4-1 0-1.3 3.7-4.1 6.1-7.3 6.1s-6-2.4-7.3-6.1c-.2-.4-.9-.4-1 0-1.3 3.7-4.1 6.1-7.3 6.1-1.5 0-3-.5-4.1-1.3-.2-.2-.6-.1-.8.1-.2.2-.1.6.1.8 1.3 1 3.1 1.5 4.8 1.5 3.2 0 6.2-2.2 7.8-5.6 1.6 3.4 4.6 5.6 7.8 5.6s6.2-2.2 7.8-5.6c1.6 3.4 4.6 5.6 7.8 5.6 1.7 0 3.4-.6 4.8-1.5.2-.2.3-.5.1-.8-.2-.2-.5-.3-.8-.1M15.4 25.2c1.6 3.4 4.6 5.6 7.8 5.6s6.2-2.2 7.8-5.6c1.6 3.4 4.6 5.6 7.8 5.6 1.7 0 3.4-.6 4.8-1.5.2-.2.3-.5.1-.8s-.5-.3-.8-.1c-1.2.9-2.7 1.3-4.1 1.3-3.1 0-6-2.4-7.3-6.1-.1-.4-.9-.4-1 0-1.3 3.7-4.1 6.1-7.3 6.1s-6-2.4-7.3-6.1c-.2-.4-.9-.4-1 0-1.3 3.7-4.1 6.1-7.3 6.1-1.5 0-3-.5-4.1-1.3-.2-.2-.6-.1-.8.1-.2.2-.1.6.1.8 1.3 1 3.1 1.5 4.8 1.5 3.2 0 6.2-2.2 7.8-5.6M15.2 15.6c3.2 0 6.2-2.2 7.8-5.6 1.6 3.4 4.6 5.6 7.8 5.6s6.2-2.2 7.8-5.6c1.6 3.4 4.6 5.6 7.8 5.6 1.7 0 3.4-.6 4.8-1.5.2-.2.3-.5.1-.8-.2-.2-.5-.3-.8-.1-1.2.9-2.7 1.3-4.1 1.3-3.1 0-6-2.4-7.3-6.1-.2-.4-.9-.4-1 0-1.3 3.7-4.1 6.1-7.3 6.1s-6-2.4-7.3-6.1c-.1-.4-.9-.4-1 0-1.3 3.7-4.1 6.1-7.3 6.1-1.5 0-3-.5-4.1-1.3-.2-.2-.6-.1-.8.1-.2.2-.1.6.1.8 1.4.9 3.1 1.5 4.8 1.5" />
    </Box>
  );
};

export const StravaYogaIcon = (props) => {
  // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 48}
      height={props.size || 48}
      viewBox="0 0 54 54"
      {...otherProps}
      sx={sx}
    >
      <path d="M23.6 32.8c-1.5-1-3.1-1.6-4.3-1.8 5.1-2 7-5.3 7-7.8 0-1.6-.7-3.2-1.8-4.2-.9-.8-2.6-1.8-5.3-1.6-5.1.4-7.9 4.4-8 4.6-.2.2-.1.5.1.7.2.2.5.1.7-.1 0 0 2.6-3.7 7.3-4.1 1.9-.2 3.5.3 4.6 1.3 1 .9 1.5 2.2 1.5 3.5 0 2.7-2.6 6.1-8.6 7.6-.2.1-.4.3-.4.6s.3.5.5.4c0 0 3.2-.1 6.2 1.9 2.6 1.8 3.9 5 3.1 7.9-.8 3-2.8 4.3-4.4 5-2.9 1.1-6.5.6-9.3-1.2-6.2-4.2-6.1-11.7-6.1-11.8 0-.3-.2-.5-.5-.5s-.5.2-.5.5-.2 8.2 6.5 12.7c1.9 1.3 4.3 2 6.6 2 1.3 0 2.5-.2 3.6-.6 2.6-1 4.4-3 5-5.6.9-3.7-.6-7.4-3.5-9.4" />
      <path d="M45.3 23.7c-1.4-1.4-3.1-2.2-5-2.1-4.1.1-6 2.9-7.5 5.1-.6.9-1.1 1.7-1.7 2.2-1.7 1.6-3.6 1.1-3.7 1-.3-.1-.5.1-.6.3-.1.3.1.5.3.6.1 0 2.5.7 4.6-1.3.7-.6 1.2-1.5 1.9-2.4 1.5-2.3 3.1-4.6 6.7-4.7 1.6-.1 3 .6 4.2 1.8 2 2 3.1 5.4 3 8.8-.1 3.5-1.7 10.1-7.3 10.2H40c-5.5 0-6.7-5.5-6.8-6.1-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6.1.7 1.5 7 7.8 7h.3c5.5-.2 8.1-5.9 8.3-11.2.2-3.5-1.1-7.2-3.3-9.4M31.4 17.6c.9.2 1.9.3 2.7.3 5.5 0 9.2-3.6 9.4-3.8.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0 0 0-4.9 4.7-11.2 3.3-5.1-1.2-6.6-5.4-6.6-5.6-.1-.3-.4-.4-.6-.3-.4-.1-.5.2-.4.5 0 .2 1.7 5 7.4 6.3" />
      <path d="M33.7 6.9c1.4 0 2.6 1.2 2.6 2.6 0 1.4-1.2 2.6-2.6 2.6-1.4 0-2.6-1.2-2.6-2.6 0-1.5 1.2-2.6 2.6-2.6m0 6.1c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6-3.6 1.6-3.6 3.6 1.6 3.6 3.6 3.6" />
    </Box>
  );
};
