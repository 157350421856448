import React from 'react';
import { Box, Link, Text } from '@primer/react';
import Container from '../elements/Container';
import { Helmet } from 'react-helmet';

const Legal = () => {
    return (
        <Container heading={"Legal"} sx={{ maxWidth: "600px" }}>
            <Helmet>
            <title>Legal | ringsync</title>
        </Helmet>
            <Box>
                <Box as="h3">Impressum</Box>
                <Box sx={{mb:2}}><Text sx={{ fontWeight: 600 }}>ringsync</Text> is owned and operated by</Box>
                <Box sx={{fontWeight:600}}>Nicolas Zimmer</Box>
                <Box>Kolonnenstraße 8, 10827 Berlin, Germany</Box>
                <Box><Link href='/contact'>Contact now</Link></Box>
                <Box sx={{mt:2}}><Text sx={{ fontWeight: 600 }}>VAT-ID</Text> DE175934966</Box>
            </Box>
            <Box>
                <Box as="h3">Trademarks</Box>
                <Box sx={{fontWeight:600, mb:3}}>ringsync is an independent service and not related to or endorsed by OURA or Strava.</Box>
                <Box>Strava and the Strava logo are registered trademarks of Strava, Inc.</Box>
                <Box>ŌURA and OURA and Ō are trademarks of Ōura Health Oy.</Box>
        </Box>
    </Container>
    )
}

export default Legal;