import React from "react";
import { useLocation } from "wouter";
import { Spinner, Box } from "@primer/react";
import { supabase } from "../supabaseClient";
import { stravaAuthClient } from "../stravaAuthClient";
import { useConnectionStore } from "../store/Store";
import { useEffect, useState } from "react";

function CallbackStrava({ messages, setMessages }) {
  const [, setLocation] = useLocation();
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    console.log(session);

    const addMessage = (message, variant = "default") => {
      setMessages((prevMessages) => [...prevMessages, { message, variant }]);
    };

    async function ProcessCallback() {
      const { user } = session;
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      if (code !== null) {
        const { accessToken, refreshToken, expiresAt, athlete } =
          await stravaAuthClient.getAccessToken(code);
        // Store the access token and refresh token in state or local storage
        console.log(accessToken, refreshToken, expiresAt, athlete);
        // Insert the access token, refresh token, and expiration time into the strava_tokens table
        const { error } = await supabase.from("strava_tokens").insert({
          user_id: user.id,
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_at: new Date(expiresAt).toISOString(),
          user_info: athlete,
          user_service_id: athlete.id,
        });
        if (error) {
          console.error(error);
          addMessage(error.message, "danger");
        } else {
          useConnectionStore.setState({ strava: athlete });
          addMessage("Successfully connected to Strava", "success");
          const today = new Date();
          // Get activities from the last week, matching the oura backfill
          today.setDate(today.getDate() - 6);
          const start_date = today.toISOString().slice(0, 10);
          const backfill = await fetch(
            `https://api.ringsync.io/functions/v1/strava-backfill/${athlete.id}?start_date=${start_date}`
          );
          const result = await backfill.json();
          console.log("CallbackStrava:backfill", result);
        }
      } else {
        console.error("No code in URL");
        const reason = searchParams.get("error");
        if (reason === "access_denied") {
          console.error("User denied access");
          addMessage("User denied access to Strava", "danger");
        } else {
          console.error("Unknown error");
          addMessage("Unknown error occurred", "danger");
        }
      }

      setLocation("/dashboard", { replace: true });
    }
    if (session) {
      ProcessCallback();
    }
  }, [session, setLocation, setMessages]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box textAlign="center">
        <h1>Connecting to Oura</h1>
        <p>
          <Spinner size="large" />
        </p>
      </Box>
    </Box>
  );
}

export default CallbackStrava;
