class OAuthClient {
    constructor(config) {
      this.config = config;
      this.accessToken = null;
      this.refreshToken = null;
      this.expiresAt = null;
    }
  
    getAuthUrl() {
      const { client, auth, callback } = this.config;
      const params = new URLSearchParams({
        client_id: client.id,
        redirect_uri: window.location.origin + callback.url,
        response_type: 'code',
        scope: auth.scope,
      });
      return `${auth.host ?? auth.tokenHost}${auth.authorizePath}?${params.toString()}`;
    }
  
    async getAccessToken(code) {
      const { client, auth, callback } = this.config;
      const params = new URLSearchParams({
        client_id: client.id,
        client_secret: client.secret,
        code,
        grant_type: 'authorization_code',
        redirect_uri: window.location.origin + callback.url,
      });
      const response = await fetch(`${auth.tokenHost}${auth.tokenPath}`, {
        method: 'POST',
        redirect: 'follow',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: params.toString(),
      });
      const data = await response.json();
      console.log(data);
      this.accessToken = data.access_token;
      this.refreshToken = data.refresh_token;
      this.expiresAt = data.expires_at *1000 || Date.now() + data.expires_in * 1000;
      //this.expiresIn = data.expires_in;
      return { accessToken: this.accessToken, refreshToken: this.refreshToken, expiresAt: this.expiresAt, athlete: data.athlete };
    }

    async signFetch(url, options = {}) {
      if (!this.accessToken) {
        throw new Error('Access token not available');
      }
      if (this.isTokenExpired()) {
        await this.refreshAccessToken();
      }
      const headers = {
        ...options.headers,
        Authorization: `Bearer ${this.accessToken}`,
      };
      return fetch(url, { ...options, headers });
    }

    isTokenExpired() {
      return Date.now() >= this.expiresAt * 1000;
    }

    async refreshAccessToken() {
      const { client, auth } = this.config;
      const params = new URLSearchParams({
        client_id: client.id,
        client_secret: client.secret,
        grant_type: 'refresh_token',
        refresh_token: this.refreshToken,
      });
      const response = await fetch(`${auth.tokenHost}${auth.tokenPath}`, {
        method: 'POST',
        redirect: 'follow',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        body: params.toString(),
      });
      const data = await response.json();
      this.accessToken = data.access_token;
      this.refreshToken = data.refresh_token;
      this.expiresAt = data.expires_at *1000 || Date.now() + data.expires_in * 1000;
      return { accessToken: this.accessToken, refreshToken: this.refreshToken, expiresAt: this.expiresAt };
    }
}
  
export default OAuthClient;