const products = [
    {
      title: 'Free Plan',
      description: 'Start syncing now, forever free.',
      price: {amount: 0, currency: '$'},
      features: ['Basic metrics', 'One activity per day', 'Update activity description only' ],
        button: { text: 'Start Now', link: 'https://ringsync.io/login' },
      upgrade: false
    },
    {
        title: 'Pro Plan',
        description: 'Save up to 50% with an annual subscription.',
        price: {amount: 5.99, currency: '$', interval:'year'},
        features: ['Advanced metrics', 'Unlimited syncing', 'Update activity description', 'Update activity title', 'Backfill 6-months', 'Remove ringsync attribution'],
        highlight: true, // highlight this product as the recommended one
        button: { text: 'Subscribe', link: 'https://buy.stripe.com/9AQg168Pmc1i2Os3cc' },
        upgrade: true
      },
    {
        title: 'Pro Plan',
        description: 'More features, for the serious athlete.',
        price: {amount: 1.00, currency: '$', interval:'month'},
        features: ['Advanced metrics', 'Unlimited syncing', 'Update activity description', 'Update activity title', 'Backfill 6-months', 'Remove ringsync attribution'],
        button: { text: 'Subcribe', link: 'https://buy.stripe.com/cN2aGMc1y4yQ88M4gh' },
        upgrade: true
      },
];
  
export default products;