import React from "react";
import { Box, SegmentedControl } from "@primer/react";

const ModelSelector = ({ models, selectedModel, onModelChange }) => (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <SegmentedControl aria-label="Switch GPT model">
        {models.map((model, index) => (
          <SegmentedControl.Button
            key={model}
            selected={model === selectedModel}
            onClick={() => onModelChange(index)}
          >
            GPT-{model.split("-")[1]}
          </SegmentedControl.Button>
        ))}
      </SegmentedControl>
    </Box>
);
  
export default ModelSelector;
  