import "./App.css";
import { useState, useEffect } from "react";
import { Route, useLocation } from "wouter";
import { supabase } from "./supabaseClient";
import { ThemeProvider, PageLayout, Box } from "@primer/react";
import HelmetBuilder from "./elements/HelmetBuilder";

import PageHeader from "./elements/PageHeader";
import BottomBar from "./elements/BottomBar";
import TopBar from "./elements/TopBar";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CallbackStrava from "./pages/CallbackStrava";
import CallbackOura from "./pages/CallbackOura";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
import TermsOfService from "./pages/TermsOfService";
import Legal from "./pages/Legal";
import Subscribe from "./pages/Subscribe";
import Profile from "./pages/Profile";
import { useActivityStore, useSleepStore, useConnectionStore } from "./store/Store";
import Activities from "./pages/Activities";
import Analysis from "./pages/Analysis";

function App() {
  const [session, setSession] = useState(null);
  const [messages, setMessages] = useState([]);
  const [location, setLocation] = useLocation();
  const fetchConnections = useConnectionStore((state) => state.fetch)
  const fetchActivities = useActivityStore((state) => state.fetch)
  const fetchSleep = useSleepStore((state) => state.fetch)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      if (event === 'SIGNED_IN')
        setLocation('/dashboard');
    });

    return () => subscription.unsubscribe();
  }, [setLocation]);

  const clearMessages = () => {
    setMessages([]);
  };

  useEffect(() => {
    console.log(location);
  }, [location]);

  useEffect(() => {
    console.log("session", session);
    if (session) {
      fetchConnections(session);
      fetchActivities(session);
      fetchSleep(session);
    }
  }, [session, fetchActivities, fetchConnections, fetchSleep]);

  return (
    <ThemeProvider preventSSRMismatch>
        <HelmetBuilder
          title="ringsync - Oura data for Strava"
          description="Add your Oura data automatically to your Strava activity description."
          url="https://ringsync.io"
          image="https://ringsync.io/ringsync_wordmark_square_black.png"
        />
        <PageLayout sx={{backgroundColor:"canvas.default", color:"fg.default"}}>
          <PageLayout.Header>
            {location.includes("dashboard" ) ? (
              <TopBar session={session} />
            ) : (
              <PageHeader session={session} />
            )}
          </PageLayout.Header>
          <PageLayout.Content sx={{ marginX: [2, 4], minHeight: ['initial', '80vh'] }}>
            <Route path="/">
              <Home session={session} />
            </Route>
            <Route path="/dashboard">
              {session ? (
                <Dashboard
                  session={session}
                  messages={messages}
                  clearMessages={clearMessages}
                  setMessages={setMessages}
                />
              ) : (
                <Home session={session} />
              )}
            </Route>
            <Route path="/callback/strava">
              <CallbackStrava
                session={session}
                messages={messages}
                setMessages={setMessages}
              />
            </Route>
            <Route path="/callback/oura">
              <CallbackOura
                session={session}
                messages={messages}
                setMessages={setMessages}
              />
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy session={session} />
            </Route>
            <Route path="/terms">
              <TermsOfService session={session} />
            </Route>
            <Route path="/contact">
              <Contact session={session} />
          </Route>
          <Route path="/activities/:variant">
              <Activities />
          </Route>
          <Route path="/activities">
              <Activities />
          </Route>
          <Route path="/profile">{
                session ? (
                  <Profile session={session} />
                ) : (
                  <Login />
                )
          }
          </Route>
          <Route path="/analysis">{
                session ? (
                  <Analysis session={session} />
                ) : (
                  <Login />
                )
          }
            </Route>
            <Route path="/support">
              <Support session={session} />
          </Route>
          <Route path="/legal">
              <Legal />
          </Route>
          <Route path="/subscribe">
              <Subscribe session={session} />
            </Route>
            <Route path="/login">
              {session ? (
                <Dashboard
                  session={session}
                  messages={messages}
                  clearMessages={clearMessages}
                  setMessages={setMessages}
                />
              ) : (
                <Login />
              )}
          </Route>
          </PageLayout.Content>
          <PageLayout.Footer>
            <BottomBar />
          </PageLayout.Footer>
        </PageLayout>
    </ThemeProvider>
  );
}

export default App;
