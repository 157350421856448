import React from "react";
import { Box, Spinner } from "@primer/react";

const CenteredSpinner = () => (
    <Box sx={{
      m: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "-webkit-fill-available",
    }}>
      <Spinner size="large" />
    </Box>
  );

export default CenteredSpinner;