import React from "react";
import { Box, Text } from "@primer/react";
import { Placeholder } from "../elements/Placeholder";
import Hero from "../elements/Hero";

function Home({ session }) {
  return (
    <>
      <Hero
        title="Automagically"
        subtitle="Oura data in your Strava Activities. Free forever."
        copy="Easy as 1, 2, 3: Connect your Oura and your Strava account, select the metrics and you're done."
      />
      <Box
        sx={{
          backgroundColor: "canvas.inset",
          p: 4,
          textAlign: ["center", "initial"],
          lineHeight: "1.2em",
          borderRadius: 15,

        }}
      >
        <Box as="h3">What can you sync?</Box>
        <Box sx={{ color: "fg.muted", pb: 3 }}>
          After linking your Oura and Strava accounts, you can choose the
          metrics you want to synchronize through ringsync. The available
          metrics include Readiness, last night's Average Heart Rate Variability (HRV),
          Average Heart Rate (HR), Resting Heart Rate (RHR), and Sleep Duration.
          <br /><br />
Once you upload an activity to Strava, ringsyc automatically updates the activity description with the latest Oura data, typically within a few seconds.
        </Box>
      </Box>
    </>
  );
}

export default Home;
