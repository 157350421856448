import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const domNode = document.getElementById("root");
const path = window.location.pathname;

if (process.env.NODE_ENV === 'production') console.log = function () {};

console.log(domNode);
console.log(path);

if (process.env.NODE_ENV === 'production' && domNode.hasChildNodes && path === "/") {
  console.log("hydrate");
  ReactDOM.hydrateRoot(
    domNode,
    <React.StrictMode>
      <App suppressHydrationWarning />
    </React.StrictMode>
  );
} else {
  console.log("create");
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
