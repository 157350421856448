import React from "react";
import { Box, Avatar, Text } from "@primer/react";
import OuraIcon from "./OuraIcon";
import { useConnectionStore } from "../store/Store";
import { useState, useEffect } from "react";

const OuraUserInfo = () => {

    const ouraUser = useConnectionStore((state) => state.oura);

    if (!ouraUser) {
        return null;
    }
    const design = ouraUser.rings[0].design.charAt(0).toUpperCase() + ouraUser.rings[0].design.slice(1);
    const color = ouraUser.rings[0].color.charAt(0).toUpperCase() + ouraUser.rings[0].color.slice(1);
    const firmware = ouraUser.rings[0].firmware_version

    return (
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: 2 }}>
            <OuraIcon size={32} />
            <Box sx={{ textAlign: "left", marginLeft: 2, lineHeight: 1.2 }}>
                <Text as="div" sx={{ marginLeft: 2, fontWeight: '600'}}>
                    {`${design} ${color}`}
                </Text>
                <Text as="div" sx={{ marginLeft: 2, fontSize: 'small' }}>
                    {`Firmware: ${firmware}`}
                </Text>
            </Box>
        </Box>
    );
};

export default OuraUserInfo;
