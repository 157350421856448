import {themeGet} from '@primer/react'
import styled from 'styled-components'
import SlidingPane from "react-sliding-pane";

const StyledSlidingPane = styled(SlidingPane)`
        background-color: ${themeGet('colors.canvas.default')};
        color: ${themeGet('colors.fg.default')};
        max-width: 42rem;
        
        .slide-pane__header {
            background-color: ${themeGet('colors.canvas.subtle')};
            border-bottom: 1px solid ${themeGet('colors.border.default')};
            .slide-pane__title-wrapper {
                margin-left: 12px;
            .slide-pane__title {
                font-weight: initial;
            }
        }
        }
    `;

export default StyledSlidingPane;