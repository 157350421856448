import React from "react";
import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { Box, Avatar, Text } from "@primer/react";
import {
    StravaActivityIcon,
    StravaBikeIcon,
    StravaEBikeIcon,
    StravaHikeIcon,
    StravaRunIcon,
    StravaSwimIcon,
    StravaWalkIcon,
    StravaYogaIcon,
} from "./RingSyncIcons";
import StravaDate from "./StravaDate";
import StravaUserInfo from "./StavaUserInfo";
import { useActivityStore } from "../store/Store";

const StravaActivityPreview = ({ activity, stravaUser, variant }) => {

    const activities = useActivityStore((state) => state.activities);
    const stravaData = activities.find((a) => a.id === activity.object_id);

    if (!stravaData) {
        return null;
    }

    let iconComponent;
    const iconSize = variant === "small" ? 32 : 24;

    switch (stravaData.sport_type) {
        case "EBikeRide":
            iconComponent = <StravaEBikeIcon size={iconSize} />;
            break;
        case "Hike":
            iconComponent = <StravaHikeIcon size={iconSize} />;
            break;
        case "Run":
        case "VirtualRun":
            iconComponent = <StravaRunIcon size={iconSize} />;
            break;
        case "Ride":
            iconComponent = <StravaBikeIcon size={iconSize} />;
            break;
        case "Yoga":
            iconComponent = <StravaYogaIcon size={iconSize} />;
            break;
        case "Swim":
            iconComponent = <StravaSwimIcon size={iconSize} />;
            break;
        case "Walk":
            iconComponent = <StravaWalkIcon size={iconSize} />;
            break;
        default:
            iconComponent = <StravaActivityIcon size={iconSize} />;
            break;
    }

    const activityDate = (
        <Box sx={{ fontSize: "small", color: "fg.subtle" }}>
            <StravaDate date={stravaData.start_date_local} />
        </Box>
    );

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 2,
                color: "fg.default",
                flexDirection: "column",
                width: "300px",
                minHeight: "45px",
                padding: "16px",
                backgroundColor: "canvas.subtle",
                borderRadius: "5px",
                maxWidth: "-webkit-fill-available",
            }}
        >
            {variant !== "small" && (
                <StravaUserInfo
                    stravaUser={stravaUser}
                    subtitle={activityDate}
                    sx={{ gap: 4 }}
                />
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 4,
                    fill: "fg.default",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "32px",
                        ml: 2,
                    }}
                >
                    {iconComponent}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column"}}>
                    {variant === "small" && activityDate}
                    <Box as="h3" sx={{ my: 0, width: "100%" }}>
                        {stravaData.title}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default StravaActivityPreview;