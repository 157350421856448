import OAuthClient from "./OAuthClient";

const config = {
    client: {
      id: '53903',
      secret: 'bf3b526a8d7617f0b273531e5003ebf0ee846114',
    },
    auth: {
      tokenHost: 'https://www.strava.com',
      authorizePath: '/oauth/authorize',
        tokenPath: '/oauth/token',
        scope: 'read,read_all,activity:read,activity:read_all,activity:write',
    },
    callback: {
      url: '/callback/strava',
    },
  };
  
  export const stravaAuthClient = new OAuthClient(config);