import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { Box, Button, useColorSchemeVar } from "@primer/react";
import { ArrowRightIcon, XIcon } from "@primer/octicons-react";
import { stravaColorScheme } from "../_shared/Backgrounds";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import StravaActivityPreview from "./StravaActivityPreview";
import StyledSlidingPane from "../components/StyledSlidingPane";
import { useActivityStore } from "../store/Store";
import { useLocation } from "wouter";


const StravaUnprocessedActivities = ({ stravaUser }) => {
    //const [stravaData, setStravaData] = useState([]);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const stravaBackground = useColorSchemeVar(stravaColorScheme);
    const [loading, setLoading] = useState(false);
    const stravaData = useActivityStore((state) => state.backfill);
    //const loading = useActivityStore((state) => state.loading);
    const [, setLocation] = useLocation();


    useEffect(() => {
        document.body.style.overflow = isPaneOpen ? "hidden" : "unset";
      }, [isPaneOpen]);


    const handleBackfill = async (activity) => {
        console.log("handleBackfill", activity);
        setLoading(true);
        const { data, error } = await supabase.functions.invoke('strava-backfill-handler', {
            body: { activities: [activity.object_id]}
        })
        if (error) {
            console.log(error);
        } else {
            console.log(data);
        }
        setLoading(false);
        setIsPaneOpen(false);
    }

    if (!stravaData || stravaData.length === 0) {
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: stravaBackground,
                    fontSize: 1,
                    mt: 3,
                    ml: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 3,
                    borderRadius: 5,
                    color: "fg.onEmphasis",
                    cursor: "pointer",
                }}
                onClick={() => setLocation('/activities/backfill')}
            >
                <Box>
                    You have {stravaData.length} unprocessed{" "}
                    {stravaData.length === 1 ? "activity" : "activities"}
                </Box>
                <Box
                    sx={{
                        color: stravaBackground,
                        backgroundColor: "fg.onEmphasis",
                        display: "flex",
                        p: 1,
                        borderRadius: "50%",
                        opacity: "80%",
                    }}
                >
                    <ArrowRightIcon size={16} />
                </Box>
            </Box>

            <StyledSlidingPane
                isOpen={isPaneOpen}
                title="Backfill activities"
                closeIcon={
                    <Box sx={{ svg: { width: 32 } }}>
                        <XIcon size={48} />
                    </Box>
                }
                width="fit-content"
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    setIsPaneOpen(false);
                }}
            >
                <Box sx={{ width: ["100vw", "60vw", "40vw"], maxWidth: 'fit-content' }}>
                    {stravaData.map((activity) => (
                        <Box sx={{mt:3}}>
                        <StravaActivityPreview
                            key={activity.id}
                            stravaUser={stravaUser}
                            activity={activity}
                            variant={"small"}
                            />
                            <Box sx={{ display: "flex", justifyContent: "flex-end", my: 4, gap:3, fontWeight:600 }}>
                                <Button onClick={() => setIsPaneOpen(false)}>Cancel</Button>
                                <Button variant="primary" onClick={() => handleBackfill(activity)} disabled={loading}>{loading ? "Importing..." : "Backfill"}</Button>
                            </Box>
                            </Box>
                    ))}
                </Box>
            </StyledSlidingPane>
        </>
    );
};

export default StravaUnprocessedActivities;
