import React, { useEffect, useState, useRef } from "react";
import Container from "../elements/Container";
import {
  Box,
} from "@primer/react";
import { SSE } from "sse.js";
import { useAutosizeTextArea } from "../_shared/Hooks";
import { Helmet } from "react-helmet";
import Conversation from "../elements/Conversation";
import PromptInput from "../elements/PromptInput";
import ModelSelector from "../elements/ModelSelector";

function Analysis({ session }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [query, setQuery] = useState("");
  const [isResponding, setIsResponding] = useState(false);
  const eventSourceRef = useRef(null); // Ref to hold the SSE instance
  const messagesEndRef = useRef(null); // Ref to track the end of messages
  const textAreaRef = useRef(null);
  const [prompt, setPrompt] = useState("");
  const [model, setModel] = useState("gpt-3.5-turbo-1106");
  const availableModels = ["gpt-3.5-turbo-1106", "gpt-4-1106-preview"];

  const { access_token } = session;

  const suggestions = [
    "What do my sleep and activity data reveal?",
    "How can I improve my running performance?",
    "What does my sleep data show about recovery?",
    "How to modify training for better results?",
  ];

  useAutosizeTextArea(textAreaRef.current, prompt);

  const handleError = (err) => {
    console.error(err);
    setLoading(false);
    setIsResponding(false);
  };

  const handleModelChange = (index) => {
    const newModel = availableModels[index];
    setModel(newModel);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Stream data function
    const streamData = () => {
      if (eventSourceRef.current) {
        return; // If the event source is already set up, do nothing
      }
      setLoading(true);
      setData(null);
      const eventSource = new SSE(
        "https://api.ringsync.io/functions/v1/ring-gpt",
        {
          headers: {
            apikey:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxvZGp1dmh2Z3Jra3RreWhvYXN2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTY1MTAxOTIsImV4cCI6MjAxMjA4NjE5Mn0.XXTtbEuPGpwEoye66rU4SzJSjrF6CrtYHzooUswNEyQ",
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
          payload: JSON.stringify({ stream: true, query: query }), //model: 'gpt-4-1106-preview',
        }
      );
      eventSource.addEventListener("error", handleError);
      eventSource.addEventListener("message", (e) => {
        try {
          setLoading(false);

          if (e.data === "[DONE]") {
            setIsResponding(false);
            return;
          }

          setIsResponding(true);
          console.log(e.data);
          const completionResponse = JSON.parse(e.data);
          const [{ delta }] = completionResponse.choices;
          console.log(delta);
          if (delta.content === "" || delta.content === undefined) {
            return;
          }
          setData((answer) => {
            return (answer ?? "") + delta.content;
          });
        } catch (err) {
          handleError(err);
        }
      });

      eventSourceRef.current = eventSource; // Store the event source instance
    };

    // Conditionally initiate the stream
    if (session && query) {
      streamData();
    }

    // Cleanup function to close the event source when the component unmounts
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, [session, access_token, query]); // Dependencies

  useEffect(() => {
    if (data) {
      //scrollToBottom(); // Scroll to bottom when data changes
    }
  }, [data]); // Dependency on data

  const handleChange = (event) => {
    const val = event.target?.value;
    setPrompt(val);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("handleSubmit", prompt);
    setQuery(prompt);
    setPrompt("");
  };

  const handleSuggestions = (suggestion) => {
    console.log("handleSuggestions", suggestion);
    setQuery(suggestion);
    setPrompt("");
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        maxWidth: "800px",
        textAlign: "left",
        mx: "auto",
        pb: 3,
      }}
      backButton="/dashboard"
    >
      <Helmet>
        <title>RingGPT | ringsync</title>
      </Helmet>
      <ModelSelector
        models={availableModels}
        model={model}
        onModelChange={handleModelChange}
      />
      <Box
        sx={{
          flex: "1", // This will take up all available space
          overflowY: "auto", // Makes the box scrollable
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "canvas.default",
            opacity: "0.95",
            height: 24,
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        ></Box>
        <Conversation
          data={data}
          loading={loading}
          suggestions={suggestions}
          handleSuggestionClick={handleSuggestions}
        />
        <Box ref={messagesEndRef} />{" "}
        {/* Invisible element at the end of messages */}
      </Box>
      <PromptInput
        prompt={prompt}
        setPrompt={setPrompt}
        handleSubmit={handleSubmit}
      />
      <Box sx={{ textAlign: "center", fontSize: "small", mt: 2 }}>
        RingGPT can make mistakes. Consider checking important information.
      </Box>
    </Container>
  );
}

export default Analysis;
