import React from "react";
import { useLocation } from "wouter";
import { Spinner, Box } from "@primer/react";
import { supabase } from "../supabaseClient";
import { ouraAuthClient } from "../ouraAuthClient";
import { useConnectionStore } from "../store/Store";

import { useEffect, useState } from "react";

function CallbackOura({messages, setMessages}) {
  const [, setLocation] = useLocation();
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);
    

  useEffect(() => {
    const addMessage = (message, variant = "default") => {
        setMessages((prevMessages) => [...prevMessages, { message, variant }]);
    };
      
    async function ProcessCallback() {
      const { user } = session;
      const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get("code");
        if (code !== null) {
            const { accessToken, refreshToken, expiresAt, athlete } =
                await ouraAuthClient.getAccessToken(code);
            // Store the access token and refresh token in state or local storage
            const response = await ouraAuthClient.signFetch(ouraAuthClient.config.auth.tokenHost + "/v2/usercollection/personal_info", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    }
                });
            const userInfo = await response.json();
          const ring = await ouraAuthClient.signFetch(ouraAuthClient.config.auth.tokenHost + "/v2/usercollection/ring_configuration", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                }
            });
        const ringConfiguration = await ring.json();
            // Insert the access token, refresh token, and expiration time into the oura_tokens table
          //const expiresAt = Date.now() + expiresIn * 1000;
          const user_info = {...userInfo, rings: ringConfiguration.data};
            const { error } = await supabase.from("oura_tokens").insert({
                user_id: user.id,
                access_token: accessToken,
                refresh_token: refreshToken,
                expires_at: new Date(expiresAt).toISOString(),
                user_info: user_info,
                user_service_id: userInfo.id,
            });
          if (error) {
            console.error(error);
            addMessage(error.message, "danger");
          } else {
            addMessage("Successfully connected to Oura", "success");
            useConnectionStore.setState({oura: user_info});
            const backfill = await fetch(`https://api.ringsync.io/functions/v1/oura-backfill/${userInfo.id}?days=7`);
            const result = await backfill.json();
            console.log("CallbackOura:backfill",result);
          }
        } else {
            console.error("No code in URL");
            const reason = searchParams.get("error");
            if (reason === "access_denied") {
                console.error("User denied access");
                addMessage("User denied access to Oura", "danger");
            } else {
                console.error("Unknown error");
                addMessage("Unknown error occurred", "danger");
            }
        }
        
      setLocation("/dashboard", { replace: true });
    }
    if (session) {
      ProcessCallback();
    }
  }, [session, setLocation, setMessages]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box textAlign="center">
        <h1>Connecting to Oura</h1>
        <p>
          <Spinner size="large" />
        </p>
      </Box>
    </Box>
  );
}

export default CallbackOura;
