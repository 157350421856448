import React from "react";
import { Box, Textarea } from "@primer/react";
import { ArrowUpIcon } from "@primer/octicons-react";

const PromptInput = ({ prompt, setPrompt, handleSubmit }) => (
    <Box as="form" sx={{
      display: "flex",
      p: [0, 3],
      backgroundColor: ["initial", "canvas.inset"],
      borderRadius: 10,
      alignItems: "center",
    }}>
      <Textarea
        rows={1}
        resize="none"
        onChange={(e) => setPrompt(e.target.value)}
        value={prompt}
        placeholder="Message RingGPT..."
        sx={{ width: "calc(100% - 48px)" }}
      />
      <SubmitButton prompt={prompt} onClick={handleSubmit} />
    </Box>
  );
  
  const SubmitButton = ({ prompt, onClick }) => (
    <Box
      sx={{
        backgroundColor: prompt ? "fg.default" : "fg.subtle",
        color: "canvas.default",
        display: "flex",
        p: 1,
        borderRadius: "50%",
        height: "32px",
        width: "32px",
        alignItems: "center",
        justifyContent: "center",
        ml: 3,
        cursor: prompt ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <ArrowUpIcon size={20} />
    </Box>
  );
  
  export default PromptInput;