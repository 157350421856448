import React from "react";
import { Box, Link, Text } from "@primer/react";
import faq from "../data/faq.json";
import Container from "../elements/Container";
import { Helmet } from "react-helmet";
import Markdown from "react-markdown";

const getFAQsStructuredData = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faq.flatMap((faqCategory) =>
      faqCategory.questions.map((question) => ({
        "@type": "Question",
        name: question.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: question.answer,
        },
      }))
    ),
  };
  return JSON.stringify(structuredData);
};

const Support = () => {
  const structuredData = getFAQsStructuredData();
  return (
    <Container heading="Support" sx={{ maxWidth: 600, textAlign: "initial" }}>
      <Helmet>
        <title>Support | ringsync</title>
        <script type="application/ld+json">{structuredData}</script>
      </Helmet>
      {faq.map((faqCategory) => (
        <Box key={faqCategory.category} mb={4}>
          <Box as="h3" mb={2}>
            {faqCategory.category}
          </Box>
          {faqCategory.questions.map((question) => (
            <Box key={question.id} mb={2}>
              <Box as="h4" mb={2}>
                {question.question}
              </Box>
              <Text>
                <Markdown
                  components={{
                    a(props) {
                      const { node, ...rest } = props;
                      return <Link {...rest} />;
                    },
                  }}
                >
                  {question.answer}
                </Markdown>
              </Text>
            </Box>
          ))}
        </Box>
      ))}
      <Box as="h3" mb={2}>
        Other Questions?
      </Box>
      <Box mb={2}>
        <Link href="/contact">Contact us</Link>
      </Box>
    </Container>
  );
};

export default Support;
