import React from "react";
import { Box, Button, Link, Header } from "@primer/react";
import LogoWordmark from "../elements/LogoWordmark";
import { useLocation } from "wouter";

function PageHeader({ session }) {
  const [location, setLocation] = useLocation();
  return (
    <Header
      sx={{
        backgroundColor: "canvas.default",
        color: "colors.fg.default",
        px: [1, 3],
      }}
    >
      <Header.Item>
        <Link href="/">
          <LogoWordmark
            size={24}
            sx={{ color: "colors.fg.default", marginY: 3, marginX: 1 }}
          />
        </Link>
      </Header.Item>
      <Header.Item full></Header.Item>
      <Header.Item sx={{mx: 1}}>
        {session ? (
          <Button variant="primary" onClick={() => setLocation("/dashboard")}>
            Dashboard
          </Button>
        ) : (
          location !== "/login" && (
            <Button variant="primary" onClick={() => setLocation("/login")}>
              Sign In
            </Button>
          )
        )}
      </Header.Item>
    </Header>
  );
}

export default PageHeader;
