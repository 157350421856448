import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "../supabaseClient";
import { Box, useTheme } from "@primer/react";
import Helmet from "react-helmet";
import Container from "../elements/Container";

export default function Login() {
  const { colorScheme}  = useTheme()
  return (
    <>
      <Helmet>
              <title>Login | ringsync</title>
              <meta name="description" content="Synchronize your Oura data automatically with your Strava activities." />
      </Helmet>
     {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box sx={{ width: "320px" }}>
      */}
      <Container
        heading={"Sign in or Sign up"}
        backButton="/dashboard"
        sx={{mb:4}}
      >
          <Auth
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={["google", "apple"]}
            redirectTo={window.location.origin + "/dashboard"}
            theme={colorScheme}
      />
      </Container>
      {/*</Box>
      </Box >
    */}
    </>
  );
}
