import React from "react";
import Connections from "../elements/Connections";
import MessageStack from "../elements/MessageStack";
import { Box } from "@primer/react";
import Helmet from "react-helmet";
import { useConnectionStore } from "../store/Store";
import { useLocation } from "wouter";
import DashboardChart from "../elements/DashboardChart";
import Divider from "../elements/Divider";

function Dashboard({ session, messages, clearMessages, setMessages }) {
  const stravaUser = useConnectionStore((state) => state.strava);
  const ouraUser = useConnectionStore((state) => state.oura);
  const [, setLocation] = useLocation();
  return (
    <>
      <Helmet>
        <title>Dashboard | ringsync</title>
      </Helmet>
      <MessageStack messages={messages} clearMessages={clearMessages} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: ["initial", "80vh", "80vh"],
          flexDirection: "column",
        }}
      >
        <Box
            sx={{
              display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            width: '-webkit-fill-available',
              "& > *": {
                // Targets direct children of the Box component
                flex: ["1 1 100%", "1 1 45%", "1 1 30%"], // Mobile, Tablet, Desktop
                margin: "10px",
                borderRadius: "5px",
                //border: "1px solid",
                //backgroundColor: "canvas.inset",
                minWidth: 250,
                minHeight: 45,
                display: "flex",
                //alignItems: "center",
                cursor: "pointer",
                p: 2,
                // Add other styles as necessary
              },
            }}
          >
        <Connections
          session={session}
          messages={messages}
          setMessages={setMessages}
        />
        {stravaUser && ouraUser && (
            <>
            <Box sx={{flex:"1 1 100%"}}>
          <Divider />
          </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Box as="h4">Heart Rate Variability</Box>
              <DashboardChart
                metric={"average_hrv"}
                service={"oura"}
                height={180}
                width={300}
                  line={{ dot: false }}
                  emaPeriod={7}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Box as="h4">Resting Heart Rate</Box>
              <DashboardChart
                metric={"lowest_heart_rate"}
                service={"oura"}
                height={180}
                width={300}
                line={{ dot: false }}
                emaPeriod={7}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Box as="h4">Running Efficiency</Box>
              <DashboardChart
                metric={"efficiency"}
                service={"strava"}
                height={180}
                width={300}
                line={{ dot: false }}
                  emaPeriod={7}
                  filter={(row)=>row.sport_type === "Run"}
              />
            </Box>
          </>
        )}
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;

//height: ['100vh', '80vh', '80vh']
