import OAuthClient from "./OAuthClient";

const config = {
  client: {
    id: "QUISOOKVHHUSA4GA",
    secret: "VSOQIJ7W4FKMEMIER5QTRQ5QUYE2GBEY",
  },
  auth: {
    host: "https://cloud.ouraring.com",
    tokenHost: "https://api.ringsync.io/functions/v1/proxy/oura",
    authorizePath: "/oauth/authorize",
    tokenPath: "/oauth/token",
    scope: "email daily sleep ring_configuration",
  },
  callback: {
    url: "/callback/oura",
  },
};

export const ouraAuthClient = new OAuthClient(config);
