import React from "react";
import { Helmet } from "react-helmet";
import PricingTable from "../elements/PricingTable";
import { Box } from "theme-ui";
import products from "../data/products";

const Subscribe = ({ session }) => {
  const title = session ? "Subscribe" : "Our Plans";
  return (
    <>
      <Helmet>
        <title>{title} | ringsync</title>
          </Helmet>
          <Box>
            <Box as="h1" sx={{mb:4}}>{title}</Box>
             <PricingTable products={products} session={session} />
            </Box>
    </>
  );
};

export default Subscribe;
