import { useLayoutEffect } from "react";
import { useTheme, SegmentedControl } from "@primer/react";

function ColorModeSwitch() {
  const { colorMode, setColorMode } = useTheme();
  const availableColorModes = ["day", "night", "auto"];

  useLayoutEffect(() => {
    const storedColorMode = localStorage.getItem("colorMode");
      setColorMode(storedColorMode ?? 'auto');
  });

  const handleColorModeChange = (index) => {
    const newColorMode = availableColorModes[index];
    setColorMode(newColorMode);
    localStorage.setItem("colorMode", newColorMode);
  };

  return (
    <SegmentedControl
      aria-label="Switch color mode"
      onChange={handleColorModeChange}
    >
      <SegmentedControl.Button selected={colorMode === "day"} value="day">
        Day
      </SegmentedControl.Button>
      <SegmentedControl.Button selected={colorMode === "night"} value="night">
        Night
      </SegmentedControl.Button>
      <SegmentedControl.Button selected={colorMode === "auto"} value="auto">
        Auto
      </SegmentedControl.Button>
    </SegmentedControl>
  );
}

export default ColorModeSwitch;
