import React from "react";
import { Link } from "wouter";
import { Box, Text } from '@primer/react';
import LogoWordmark from "./LogoWordmark";
import ColorModeSwitch from "./ColorModeSwitch";

function BottomBar() {
    const currentYear = new Date().getFullYear();

    return (
        <Box as="footer" sx={{my:4}} color="fg.subtle">
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr', 'center', 'repeat(auto-fit, minmax(150px, 1fr))'],
                    gridGap: [3, 2],
                    justifyContent: ['center', 'center', 'stretch'],
                    alignItems: 'center',
                    textAlign: 'center',
                    fontSize: 1,
                    justifyItems: 'center',
                    marginX: 2,
                    marginY: 2
                }}
            >
                <LogoWordmark size={20} sx={{color:'colors.fg.muted', justifySelf:['center', 'center', 'center','start']}} />
                <Link href="/privacy">Privacy Policy</Link>
                <Link href="/terms">Terms</Link>
                <Link href="/legal">Legal</Link>
                <Link href="/contact">Contact</Link>
                <Link href="/support">Support/FAQ</Link>
                <ColorModeSwitch />
            </Box>
            <Box sx={{textAlign: ['center', 'center', 'center', 'left'], marginX: 2, marginTop: 3}}>
                <Text sx={{color:'colors.fg.muted', fontSize: 1,}}>&copy; {currentYear} ringsync</Text>
            </Box>
        </Box>
    );
}

export default BottomBar;
