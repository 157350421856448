import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextInput,
  Textarea,
  Button,
  Box,
  Octicon,
} from "@primer/react";
import { CheckIcon } from "@primer/octicons-react";
import { Helmet } from "react-helmet";

function Contact({ session }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [user, setUser] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (session) {
      setName(session.user.user_metadata.full_name);
      setEmail(session.user.email);
      setUser(session.user.id);
    }
  }, [session]);

  const validateEmail = (email) => {
    // RFC 5322 standard email validation regex
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateEmail(email)) {
      // Send the form data to the server
      console.log("Name:", name);
      console.log("Email:", email);
      console.log("Message:", message);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          name: name,
          email: email,
          message: message,
          uid: user,
        }),
      })
        .then(() => {
          setEmailError("");
          setSubmitted(true);
        })
        .catch((error) => alert(error));
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  return (
    <>
       <Helmet>
            <title>Contact | ringsync</title>
        </Helmet>
        <Box as="h2" sx={{maxWidth: "360px", textAlign:['center','initial'], margin:'auto', mb:3}}>Contact Us</Box>
        {submitted ? (
          <Box
            sx={{
              maxWidth: "360px",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Octicon
              icon={CheckIcon}
              size={48}
              color="success.fg"
              sx={{ mr: 2 }}
            />
            <Box as="h3">
              {" "}
              Thank you for your message!
              <br />
              We will get back to you shortly.
            </Box>
          </Box>
        ) : (
          <Box
            as="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "360px",
              gap: 3,
              margin: "auto",
              "> *": {
                width: "100%",
                alignItems: "stretch!important",
              },
            }}
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="uid" value={user} />
            <FormControl>
              <FormControl.Label>Name</FormControl.Label>
              <TextInput
                id="name"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl required>
              <FormControl.Label>Email</FormControl.Label>
              <TextInput
                id="email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
                aria-describedby="email-error"
              />
              {emailError && (
                <FormControl.Validation id="email-error" variant="error">
                  {emailError}
                </FormControl.Validation>
              )}
            </FormControl>
            <FormControl required>
              <FormControl.Label>Message</FormControl.Label>
              <Textarea
                id="message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                rows={5}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: ["center", "right"],
                marginTop: 2,
              }}
            >
              <Button type="submit" variant="primary">
                Send Message
              </Button>
            </Box>
          </Box>
      )}
    </>
  );
}

export default Contact;
