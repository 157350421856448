import { useState, useEffect } from 'react';

export const viewportRanges = {
  extraSmall: '(max-width: 479.98px)', // Phones
  small: '(min-width: 480px) and (max-width: calc(767.98px))', // Larger phones to small tablets
  medium: '(min-width: 768px) and (max-width: calc(1399.98px))', // Tablets to lower-end desktop screens
  large: '(min-width: 1400px)', // Larger desktop screens
};

export function useViewportType() {
  const [viewportType, setViewportType] = useState('medium'); // Default to 'medium'

  useEffect(() => {
    function updateViewportType() {
      if (window.matchMedia(viewportRanges.extraSmall).matches) {
        setViewportType('extraSmall');
      } else if (window.matchMedia(viewportRanges.small).matches) {
        setViewportType('small');
      } else if (window.matchMedia(viewportRanges.large).matches) {
        setViewportType('large');
      } else {
        setViewportType('medium');
      }
    }

    updateViewportType(); // Set initial viewport type

    window.addEventListener('resize', updateViewportType); // Add resize listener

    // Cleanup function
    return () => {
      window.removeEventListener('resize', updateViewportType);
    };
  }, []);

  return viewportType;
}

// Updates the height of a <textarea> when the value changes.
export const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = `calc(${scrollHeight}px - 1.8em)`;
    }
  }, [textAreaRef, value]);
};

//export default useAutosizeTextArea;