import React from "react";
import ActivityTable from "../elements/ActivityTable";
import Container from "../elements/Container";
import { useActivityStore } from "../store/Store";
import { useParams } from "wouter";
import { supabase } from "../supabaseClient";
import { Helmet } from "react-helmet";

function Activities({ session }) {

    let activities = useActivityStore((state) => state.activities);
    const loading = useActivityStore((state) => state.loading);
    const backfill = useActivityStore((state) => state.backfill).map((a) => a.object_id);
    const params = useParams();
    let action = null;

    const handleBackfill = async (activities) => {
        console.log("handleBackfill", activities);
        if (!activities || activities.length === 0) {
            return;
        }
        useActivityStore.setState({ loading: true });
        const { data, error } = await supabase.functions.invoke('strava-backfill-handler', {
            body: { activities: Array.from(activities) }
        })
        if (error) {
            console.log(error);
        } else {
            console.log(data);
            const updatedBackfill = backfill.filter((a) => !activities.has(a));
            useActivityStore.setState({ backfill: updatedBackfill });
        }
        useActivityStore.setState({ loading: false });
    }

    switch (params.variant) {
        case "backfill":
            action = handleBackfill
            activities = activities.filter((a) => backfill.includes(a.id));
            break;
        default:
            break;
    }

    return (
        <>
            <Helmet>
            <title>Activities | ringsync</title>
        </Helmet>
        <Container sx={{ maxWidth: "800px", textAlign: "left", paddingBottom: 4 }} backButton="/dashboard">
            <ActivityTable activities={activities} loading={loading} variant={params.variant} action={action} />
            </Container>
        </>
    );
}
    
export default Activities;