import React from "react";
import { Box } from "@primer/react";
import { Logo } from "./Branding";

const ChatAvatar = ({ size, sx }) => {
  return (
    <Box
      sx={{
        backgroundColor: "fg.default",
        display: "flex",
        p: 1,
        borderRadius: "50%",
        width: size,
              height: size,
              alignItems: "center",
                justifyContent: "center",
                ...sx,
      }}
    >
      <Logo size={size * 0.8} sx={{ color: "colors.canvas.default", strokeWidth:"8px" }} />
    </Box>
  );
};

export default ChatAvatar;
