import React from "react";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { WebPage } from "schema-dts";

type HelmetBuilderProps = {
  title: string;
  description: string;
  url: string;
  image: string;
};

const HelmetBuilder = ({
  title,
  description,
  url,
  image,
}: HelmetBuilderProps) => {
  return (
    <>
      <Helmet script={[
        helmetJsonLdProp<WebPage>({
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: title,
          description: description,
          url: url,
          image: image,
        }),
      ]}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
      </Helmet>
    </>
  );
};

export default HelmetBuilder;
