import React from "react";
import { Box, Avatar, Text } from "@primer/react";
import StravaPremiumBadge from "./StravaPremiumBadge";

const StravaUserInfo = ({ stravaUser, subtitle, sx }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 2,
        color: "fg.default",
        gap: 3,
        ...sx,
      }}
      >
            <Box>
              <Avatar src={stravaUser.profile} size={32} />
              {(stravaUser.summit || stravaUser.premium) &&
                  <StravaPremiumBadge size={18} style={{
                      position: "absolute",
                      transform: "translate(-50%, -25%)"
                  }} />
              }
            </Box>
      <Box>
        <Box sx={{fontWeight: 600 }}>
          {stravaUser.firstname} {stravaUser.lastname}
        </Box>
        {subtitle && (<Box>{subtitle}</Box>)}
      </Box>
    </Box>
  );
};

export default StravaUserInfo;
