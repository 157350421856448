import * as React from "react";
import { themeGet, Box, useTheme } from '@primer/react'

export const Logo = (props) => {
   // Extract the sx prop from the received props
  const { size, sx, ...otherProps } = props;
  const theme = useTheme();
    const fill = sx && sx.color ? themeGet(sx.color)(theme) || sx.color : "#000";
    const strokeWidth = sx && sx.strokeWidth ? sx.strokeWidth : "5px";

  return (
      <Box
          as="svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80 80"
          width={size || 50}
          height={size || 50}
          {...otherProps}
          sx={sx}
      >
      <defs>
      <style>
        {
          `.b{fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:${strokeWidth}}`
        }
      </style>
    </defs>
    <path
      d="M25.81 17.8c-12.15 0-22 9.85-22 22s9.85 22 22 22 22-9.85 22-22"
        className="b"
        stroke={fill || "#000"}
    />
    <path
      d="M54.19 62.2c12.15 0 22-9.85 22-22s-9.85-22-22-22-22 9.85-22 22"
        className="b"
        stroke={fill || "#000"}
    />
        </Box>
    );
}

export const LogoWordmark = (props) => {

    // Extract the sx prop from the received props
    const { size, sx, ...otherProps } = props;
    const theme = useTheme();
    const fill = sx && sx.color ? themeGet(sx.color)(theme) || sx.color : "#000";
  
    return (
        <Box
            as="svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 280 50"
            width={size * 5.6 || 280}
            height={size || 50}
            {...otherProps}
            sx={sx}
        >
        <defs>
          <style>
            {`.icon{fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:5px}`}
          </style>
        </defs>
        <g id="b">
          <g id="c">
            <path
              d="M27.05 2.8c-12.15 0-22 9.85-22 22s9.85 22 22 22 22-9.85 22-22"
              className="icon"
              stroke={fill || "#000"}
            />
            <path
              d="M55.44 47.2c12.15 0 22-9.85 22-22s-9.85-22-22-22-22 9.85-22 22"
              className="icon"
              stroke={fill || "#000"}
            />
            <path
              fill={fill || "#000"}
              d="M98.25 12.97h5.04v3.41c1.73-2.21 4.51-3.94 7.54-3.94v4.99c-.43-.1-.96-.14-1.58-.14-2.11 0-4.94 1.44-5.95 3.07v15.79h-5.04V12.97ZM115.28 6.01c0-1.73 1.44-3.12 3.12-3.12s3.12 1.39 3.12 3.12-1.39 3.12-3.12 3.12-3.12-1.39-3.12-3.12Zm.62 6.96h5.04v23.19h-5.04V12.97ZM144.13 21.66c0-3.6-1.82-4.8-4.66-4.8-2.59 0-4.8 1.54-5.95 3.12v16.18h-5.04V12.97h5.04v3.17c1.54-1.82 4.51-3.74 8.16-3.74 4.99 0 7.49 2.69 7.49 7.44v16.32h-5.04v-14.5ZM157.75 38.46c1.87 2.16 4.27 2.98 7.34 2.98 3.31 0 6.87-1.54 6.87-6.29v-2.78c-1.78 2.3-4.42 3.84-7.44 3.84-5.9 0-10.27-4.27-10.27-11.9s4.32-11.91 10.27-11.91c2.93 0 5.57 1.34 7.44 3.79v-3.22H177v21.99c0 8.35-6.19 10.61-11.91 10.61-3.94 0-6.86-.86-9.7-3.46l2.35-3.65Zm14.21-18.48c-1.1-1.73-3.6-3.12-5.95-3.12-3.98 0-6.53 2.98-6.53 7.44s2.54 7.44 6.53 7.44c2.35 0 4.85-1.39 5.95-3.12v-8.64ZM183.82 29.48c1.63 1.68 4.85 3.26 7.87 3.26s4.56-1.1 4.56-2.83c0-2.02-2.45-2.74-5.28-3.36-3.98-.86-8.74-1.92-8.74-7.1 0-3.79 3.26-7.06 9.12-7.06 3.94 0 6.91 1.39 9.02 3.26l-2.11 3.55c-1.39-1.58-3.98-2.78-6.87-2.78-2.64 0-4.32.96-4.32 2.59 0 1.78 2.3 2.4 5.04 3.02 4.03.86 8.98 2.02 8.98 7.49 0 4.08-3.41 7.2-9.65 7.2-3.94 0-7.54-1.25-9.94-3.6l2.3-3.65ZM207.87 41.1c1.58 0 2.64-.48 3.31-2.02l1.15-2.64-9.5-23.47h5.38l6.77 17.38 6.77-17.38h5.42l-11.18 27.36c-1.58 3.94-4.37 5.18-7.97 5.23-.72 0-2.11-.14-2.83-.34l.77-4.51c.53.24 1.34.38 1.92.38ZM245.98 21.66c0-3.6-1.82-4.8-4.66-4.8-2.59 0-4.8 1.54-5.95 3.12v16.18h-5.04V12.97h5.04v3.17c1.54-1.82 4.51-3.74 8.16-3.74 4.99 0 7.49 2.69 7.49 7.44v16.32h-5.04v-14.5ZM275.15 29.01c-.56 0-1.06.21-1.45.55l-.03-.03c-1.3 1.78-3.07 2.74-5.33 2.74-4.22 0-7.01-3.22-7.01-7.73s2.78-7.68 7.01-7.68c2.26 0 4.03.91 5.33 2.74l.03-.02a2.236 2.236 0 0 0 3.75-1.65c0-.52-.19-1-.48-1.38l.03-.02c-1.63-2.21-4.37-4.13-8.88-4.13-7.06 0-12 5.14-12 12.15s4.94 12.19 12 12.19c4.51 0 7.25-1.92 8.88-4.13l-.03-.03c.27-.37.44-.83.44-1.32 0-1.24-1-2.24-2.24-2.24Z"
            />
          </g>
        </g>
      </Box>
    );
  };