import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { Box, Text, Link } from "@primer/react";
import { RelativeTime } from "@primer/react";
import { ClockIcon } from "@primer/octicons-react";

import { useActivityStore } from "../store/Store";


const StravaLatestActivity = ({ stravaUser }) => {

  const stravaData = useActivityStore((state) => state.latest);

  if (!stravaData) {
    return null;
  }

  const { object_id, processed } = stravaData;

  return (
    <Box
      sx={{
        color: "fg.subtle",
        fontSize: 1,
        mt: 3,
        ml: 1,
        display: "flex",
        gap: 1,
        alignItems: "center",
      }}
    >
      {processed ? (
        <>
          <Link
            href={`https://www.strava.com/activities/${object_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Last activity
          </Link>
          <Text>synced</Text>
          <RelativeTime date={new Date(`${processed}+00:00`)} />
        </>
      ) : (
        <Text>No activity synced yet.</Text>
      )}
    </Box>
  );
};

export default StravaLatestActivity;
