import React from "react";
import { Box, Button, Link, useTheme } from "@primer/react";
import ScreenshotJPG from "../assets/strava_screen.jpg";
import ScreenshotWEBP from "../assets/strava_screen.webp";

const Hero = ({ title, subtitle, copy }) => {
  const { colorScheme } = useTheme();
  return (
    <Box
      sx={{
        flexDirection: ["column", "row"],
        display: "flex",
        gap: 4,
        justifyContent: "stretch",
        alignItems: ["start", "center"],
        mb: 6,
      }}
    >
      <Box sx={{ width: ["100%", "50%"], textAlign: ["center", "initial"] }}>
        <Box as="h1" sx={{ my: 1 }}>
          {title}
        </Box>
        <Box as="h3" sx={{ my: 1 }}>
          {subtitle}
        </Box>
        <Box as="p" sx={{ my: 3, color: "fg.muted", lineHeight: "1.2em" }}>
          {copy}
        </Box>
        <Button
          as={Link}
          href="/login"
          variant="primary"
          size="large"
          sx={{ marginTop: 2 }}
        >
          Start Syncing
        </Button>
      </Box>
      <Box
        sx={{
          width: ["100%", "50%"],
          boxShadow: colorScheme === "light" ? "10px 10px 8px #888888;" : "0",
        }}
      >
        <picture>
          <source srcSet={ScreenshotWEBP} />
          <img
            src={ScreenshotJPG}
            alt="Activity with Oura data on Strava"
            width="100%"
          />
        </picture>
      </Box>
    </Box>
  );
};

export default Hero;
