import * as React from "react";
const StravaIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Strava"
    viewBox="0 0 512 512"
    width={props.size || 124}
    height={props.size || 124}
    {...props}
  >
    <rect width={512} height={512} fill="#fc4c01" rx="15%" />
    <path fill="#fff" d="M120 288 232 56l112 232h-72l-40-96-40 96z" />
    <path fill="#fda580" d="m280 288 32 72 32-72h48l-80 168-80-168z" />
  </svg>
);
export default StravaIcon;
