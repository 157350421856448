import React from "react";
import { DataTable, Table } from "@primer/react/drafts";
import { Button, Text, Checkbox, Truncate, Box } from "@primer/react";
import StravaDate from "./StravaDate";
import { useState, useEffect } from "react";
import { useViewportType } from "../_shared/Hooks";

const ActivityTable = ({ activities, loading, action, variant }) => {

    const [selectedRows, setSelectedRows] = useState(new Set());
    var actionTitle = "";
    var description = "";
    const viewportType = useViewportType();
    const hiddenColumns = viewportType === "extraSmall" ? ["sport_type"] : [];
    const truncateAt = viewportType === "extraSmall" ? 180 : 250;
    const pageSize = 10
    const [pageIndex, setPageIndex] = useState(0)
    //const start = pageIndex * pageSize
    //const end = start + pageSize

    const [currentPageRows, setCurrentPageRows] = useState([]);

    useEffect(() => {
        // Sort activities
        const sortedActivities = Array.from(activities).sort((a, b) => {
            return b.start_date_local - a.start_date_local;
        });

        // Calculate start and end indices for slicing
        const start = pageIndex * pageSize;
        const end = start + pageSize;

        // Slice the sorted array to get only the rows for the current page
        const pageRows = sortedActivities.slice(start, end);

        // Set the current page rows
        setCurrentPageRows(pageRows);
    }, [activities, pageIndex, pageSize]);
 
    // Function to toggle individual row selection
  const toggleRowSelection = (rowId) => {
    const newSelection = new Set(selectedRows);
    if (newSelection.has(rowId)) {
      newSelection.delete(rowId);
    } else {
      newSelection.add(rowId);
    }
      setSelectedRows(newSelection);
      console.log("toggleRowSelection", newSelection);
  };

  // Function to select or deselect all rows
  const toggleSelectAll = () => {
    if (selectedRows.size === activities.length) {
      setSelectedRows(new Set()); // Deselect all
    } else {
      const newSelection = new Set(activities.map(row => row.id));
      setSelectedRows(newSelection); // Select all
    }
      console.log("toggleSelectAll", selectedRows);
  };
    
     // Check if all rows are selected
    const isAllSelected = activities.length && selectedRows.size === activities.length;
    const handleBulkAction = () => {
        console.log("handleBulkAction", selectedRows);
        if (action) {
            action(selectedRows);
        }
    }


    var columns = [
    {
      header: "Name",
      field: "title",
      rowHeader: true,
      renderCell: (row) => { return (<Truncate maxWidth={truncateAt} title={row.title} inline>{row.title}</Truncate>) },
      sortBy: "alphanumeric",
    },
    {
      header: "Type",
      field: "sport_type",
      sortBy: "alphanumeric",
    },
    {
      header: "Date",
      field: "start_date_local",
      renderCell: (row) => {
        return (
          <>
            <Text sx={{ display: ["initial", "none"] }}>
              {new Date(row.start_date_local).toLocaleDateString()}
            </Text>
            <Text sx={{ display: ["none", "initial"] }}>
              <StravaDate date={row.start_date_local} />
            </Text>
          </>
        );
      },
      sortBy: "datetime",
    },
    ];
    
    if (action) {
        columns = [
            {
                header: () => (
                    <Checkbox
                        checked={isAllSelected}
                        //indeterminate={selectedRows.size > 0 && !isAllSelected}
                        onChange={toggleSelectAll}
                    />
                ),
                renderCell: (row) => (
                    <Checkbox
                        checked={selectedRows.has(row.id)}
                        onChange={() => toggleRowSelection(row.id)}
                    />
                ),
                id: "checkbox",
                width: 40,
            },
            ...columns,
        ]
    }

    switch (variant) {
        case "backfill":
            actionTitle = "Backfill";
            description = "Select the activities you want to backfill";
            break;
        default:
            columns = [
                ...columns,
                {
                    header: "Efficiency",
                    field: "efficiency",
                    renderCell: (row) => { return  ( row.efficiency && <Text>{ row.efficiency.toFixed(1) }</Text>) },
                  },
            ]
            break;
    }

    if (hiddenColumns.length > 0) {
        columns = columns.filter((column) => !hiddenColumns.includes(column.field));
    }

  return (
    <Table.Container>
      <Table.Title as="h2" id="table-title">
        Activities
          </Table.Title>
          {description &&
              <Table.Subtitle as="p" id="table-subtitle">
                 {description}
              </Table.Subtitle>
          }
          { action &&
              <Table.Actions>
                  {/* Update actions based on selected rows */}
                  <Button onClick={handleBulkAction} variant="primary" size="small" disabled={selectedRows.size === 0 || loading}>{ loading ? "Loading..." : actionTitle }</Button>
              </Table.Actions>
          }
          {loading ? (
              <Table.Skeleton
                  aria-labelledby="repositories"
                  aria-describedby="repositories-subtitle"
                  columns={columns}
                  rows={5}
              />
          ) : (
              <DataTable
                  aria-labelledby="table-title"
                  aria-describedby="table-subtitle"
                  columns={columns}
                  data={currentPageRows}
                  initialSortColumn="start_date_local"
                  initialSortDirection="DESC"
              />
          )}
          {activities.length > pageSize &&
                <Table.Pagination
                    label="Table navigation"
                    currentPage={pageIndex}
                    totalCount={activities.length}
                    pageSize={pageSize}
                    onChange={({ pageIndex }) => {
                        setPageIndex(pageIndex)
                    }}
                  />
            }
    </Table.Container>
  );
};

export default ActivityTable;
