import { create } from "zustand";
import { supabase } from "../supabaseClient";

// Shared function to fetch Strava data
async function _fetchStravaData(userId) {
  const { data, error } = await supabase
    .from("strava_tokens")
    .select("*")
    .eq("user_id", userId)
    .single();
  if (error) throw error;
  return data;
}

export const useConnectionStore = create((set) => ({
  strava: null,
  oura: null,
  loading: true,
  fetch: async (session) => {
    set({ loading: true });
    const { user } = session;

    try {
      // Fetch Oura and Strava data concurrently
      const fetchOuraData = async () => {
        const { data: ouraData, error: ouraError } = await supabase
          .from("oura_tokens")
          .select("*")
          .eq("user_id", user.id)
          .single();
        if (ouraError) {
          console.warn("useConnectionStore:fetch:oura", ouraError);
        } else {
          set({ oura: ouraData.user_info });
        }
      };
      const fetchStravaData = async () => {
        const stravaData = await _fetchStravaData(user.id);
        set({ strava: stravaData.user_info });
      };
      await Promise.all([fetchOuraData(), fetchStravaData(user.id)]);
    } catch (error) {
      console.warn("useConnectionStore:fetch", error);
    } finally {
      set({ loading: false });
    }
  },
}));

export const useActivityStore = create((set) => ({
  loading: true,
  activities: [],
  backfill: [],
  latest: null,
  fetch: async (session) => {
    set({ loading: true });
    const { user } = session;

    try {
      const stravaData = await _fetchStravaData(user.id);
      // Fetch activity data, backfill data, and latest activity concurrently
      const fetchActivityData = async () => {
        const { data: activityData, error: activityError } = await supabase
          .from("strava_activity_data")
          .select("*")
          .eq("user_id", user.id)
          .order("start_date", { ascending: false }); // Sort by start_date in descending order
        if (activityError) {
          console.warn("useActivityStore:fetch:activity", activityError);
        } else {
          set({ activities: activityData });
        }
      };
      const fetchBackfillData = async () => {
        const { data: backfillData, error: backfillError } = await supabase
          .rpc('get_backfill_transactions', { user_id_input: user.id })
        if (backfillError) {
          console.warn("useActivityStore:fetch:backfill", backfillError);
        } else {
          set({ backfill: backfillData });
        }
      }
      const fetchLatestActivity = async () => {
        console.log("fetchLatestActivity",stravaData.user_info.id);
        const { data: latestActivityData, error: latestActivityError } = await supabase
          .from("strava_transactions")
          .select("*")
          .eq("owner_id", stravaData.user_info.id)
          .eq("aspect_type", "create")
          .eq("object_type", "activity")
          .not("processed", "is", null)
          .order("event_time", { ascending: false })
          .limit(1);
        if (latestActivityError) {
          console.warn("useActivityStore:fetch:backfill", latestActivityError);
        } else if (latestActivityData && Array.isArray(latestActivityData) && latestActivityData.length > 0) {
          console.log(latestActivityData);
          set({ latest: latestActivityData[0] })
        }
      };

      await Promise.all([fetchActivityData(), fetchBackfillData(), fetchLatestActivity()]);
    } catch (error) {
      console.warn("useActivityStore:fetch", error);
    } finally {
      set({ loading: false });
    }
  },
}));

// Sleep data
export const useSleepStore = create((set) => ({
  loading: true,
  sleepData: [],
  fetch: async (session) => {
    set({ loading: true });
    const { user } = session;

    try {
      const { data: sleepData, error: sleepError } = await supabase
        .from("oura_sleep_data")
        .select("*")
        .eq("user_id", user.id)
        .eq("type", "long_sleep")
        .order("day", { ascending: true });
      if (sleepError) {
        console.warn("useSleepStore:fetch", sleepError);
      } else {
        set({ sleepData: sleepData });
      }
    } catch (error) {
      console.warn("useSleepStore:fetch", error);
    } finally {
      set({ loading: false });
    }
  },
}));
