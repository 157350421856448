import React from 'react';
import Markdown from "react-markdown";
import terms from "../markdown/terms.js";

function TermsOfService() {
    return (
        <Markdown>{terms}</Markdown>
    );
}

export default TermsOfService;
