import React from "react";
import { Box } from "@primer/react";

const Divider = () => (
    <Box
        sx={{
            height: "1px",
            backgroundColor: "border.default",
            my: 2,
            width: "100%"
        }}
    ></Box>
)

export default Divider;