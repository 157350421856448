import React from "react";
import { Header, Box, Avatar, ActionMenu, ActionList, Text } from "@primer/react";
import { PencilIcon, PersonIcon, TrashIcon } from "@primer/octicons-react";
import { supabase } from "../supabaseClient";
import LogoWordmark from "../elements/LogoWordmark";
import md5 from "md5";
import { useLocation } from "wouter";
//import { useState } from 'react';

function TopBar({ session }) {
  const [, setLocation] = useLocation();
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
    setLocation("/");
  };

  if (!session) {
    setLocation("/");
    return null;
  }

  const { user } = session;
  const name = user.user_metadata.full_name || user.email;
  const email_md5 = md5(user.email.toLowerCase().trim());
  const picture =
    user.user_metadata.avatar_url ??
    `https://www.gravatar.com/avatar/${email_md5}?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${name}/128`;

  return (
    <Header sx={{ backgroundColor: "canvas.default", color: "colors.fg.default", px: [1, 3] }}>
      <Header.Item>
        <Header.Link to="/" href="/">
        <LogoWordmark size={24} sx={{ color: "colors.fg.default", marginY: 3, marginX: 1 }} />
        </Header.Link>
      </Header.Item>
      <Header.Item full></Header.Item>
      <Header.Item>
        <ActionMenu>
          <ActionMenu.Anchor>
            <Header.Link variant="invisible" aria-label="Open column options">
              <Box sx={{
                display: 'flex',
                gap: 3,
                alignItems: 'center',
                //mr: 1,
              }}>
                <Avatar src={picture} size={32} />
                <Text sx={{
                  display: ['none', 'block', 'block'],
                  color: "fg.default"
                }}>{name}</Text>
              </Box>
            </Header.Link>
          </ActionMenu.Anchor>
          <ActionMenu.Overlay>
            <ActionList>
              <ActionList.Item onClick={()=>setLocation('/profile')}>
                <ActionList.LeadingVisual>
                  <PersonIcon />
                </ActionList.LeadingVisual>
                My Profile
              </ActionList.Item>
              <ActionList.Divider />
              <ActionList.Item onClick={()=>setLocation('/activities')}>
                My Activities
              </ActionList.Item>
              <ActionList.Item onClick={()=>setLocation('/analysis')}>
                RingGPT
              </ActionList.Item>
              <ActionList.Divider />
              <ActionList.Item onClick={handleLogout}>
                Sign out
              </ActionList.Item>
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </Header.Item>
    </Header>
  );
}

export default TopBar;

/*
{isMenuOpen && (
          <ActionMenu onClose={() => setIsMenuOpen(false)}>
            <ActionMenu.Item onClick={() => console.log('Upgrade clicked')}>Upgrade</ActionMenu.Item>
            <ActionMenu.Item onClick={handleLogout}>Sign off</ActionMenu.Item>
          </ActionMenu>
          */
