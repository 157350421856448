import React, { useState } from "react";
import { Button, TextInput, FormControl, Box, Text } from "@primer/react";

const ConfirmationBox = ({ session, text, buttonLabel, callback }) => {
  const [email, setEmail] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleButtonClick = () => {
    setShowInput(true);
    setErrorMessage("");
  };

  const handleConfirmClick = () => {
    if (email === session.user.email) {
      callback();
    } else {
      setErrorMessage("Email doesn't match.");
    }
  };

  return (
    <Box sx={{ display: "grid", gridGap: 3, width:'100%' }}>
      <Box sx={{ color: 'fg.muted'}}>
        {showInput
          ? <>Type your account's email <Text sx={{ fontWeight: 600 }}>{session.user.email}</Text> in the text field and click confirm:</>
          : text}
      </Box>
      {showInput && (
        <FormControl>
          <FormControl.Label>Email</FormControl.Label>
                  <TextInput value={email} onChange={(e) => setEmail(e.target.value)} sx={{width:'100%'}} />
          {errorMessage && (
            <FormControl.Validation variant="error">
              {errorMessage}
            </FormControl.Validation>
          )}
        </FormControl>
      )}
      <Box sx={{width:'100%', display:"flex", justifyContent:"end"}}>
        <Button
          variant="danger"
          sx={{ fontWeight: 600 }}
          onClick={showInput ? handleConfirmClick : handleButtonClick}
        >
          {showInput ? "Confirm" : buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmationBox;
