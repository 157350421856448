import React from "react";
import Markdown from "react-markdown";
import Suggestions from "./Suggestions";
import CenteredSpinner from "./CenteredSpinner";
import { Box } from "@primer/react";
import ChatAvatar from "./ChatAvatar";

const Conversation = ({
  data,
  suggestions,
  loading,
  handleSuggestionClick,
}) => {
  if (data) {
    return (
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <ChatAvatar size={18} sx={{ mr: 2 }} />
          <Box sx={{ fontWeight: "bold" }}>RingGPT</Box>
        </Box>
        <Markdown>{data}</Markdown>
      </Box>
    );
  } else if (loading) {
    return <CenteredSpinner />;
  } else {
    return (
      <Suggestions
        suggestions={suggestions}
        onSuggestionClick={handleSuggestionClick}
      />
    );
  }
};

export default Conversation;
