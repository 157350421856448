import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Button, Checkbox, Text, ToggleSwitch, CheckboxGroup, FormControl } from '@primer/react';
import { isEqual } from 'lodash';

const possibleValues = {
    'readiness':'Readiness',
    'average_hrv': 'Average Heart Rate Variability (HRV)',
    'average_heart_rate': 'Average Heart Rate (HR)',
    'lowest_heart_rate': 'Resting Heart Rate (RHR)',
    'total_sleep_duration': 'Sleep Duration',
};

const TemplateEditor = ({ session, messages, setMessages }) => {
    const [template, setTemplate] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [selectedCheckboxValues, setSelectedCheckboxValues] = useState([])
    const [templateId, setTemplateId] = useState(null);
    const [userServiceId, setUserServiceId] = useState(null);

    const addMessage = (message, variant = "default") => {
        setMessages((prevMessages) => [...prevMessages, { message, variant }]);
    };

    useEffect(() => {
        const fetchTemplate = async () => {
            //console.log(session.user);
            const { data: tokenData, error: tokenError } = await supabase
                .from('strava_tokens')
                .select('*')
                .eq('user_id', session.user.id)
                .single();

            if (tokenError || !tokenData) {
                console.error(tokenError);
                addMessage(tokenError.message, "danger");
            } else {
                //console.log(tokenData);
                setUserServiceId(tokenData.user_service_id);

                const { data: templateData, error: templateError } = await supabase
                    .from('strava_templates')
                    .select('*')
                    .eq('user_id', session.user.id)
                    .single();

                if (templateError || !templateData) {
                    console.error(templateError);
                    //addMessage(templateError.message, "danger");
                } else {
                    setTemplateId(templateData.id);
                    setTemplate(templateData.template.description.sort());
                    setSelectedCheckboxValues(templateData.template.description.sort());
                }
            }
        };
        fetchTemplate();
    }, [session.user]);

    const handleReset = () => {
        setSelectedCheckboxValues(template);
        setIsDirty(false);
    };

    const handleSave = async () => {
        const { error } = await supabase
            .from('strava_templates')
            .upsert({ user_id: session.user.id, user_service_id: userServiceId, template: { description: selectedCheckboxValues } },  { onConflict: "user_id" })
            .eq('user_id', session.user.id);

        if (error) {
            console.error(error);
            addMessage(error.message, "danger");
        } else {
            addMessage(templateId ? "Your template has been updated." : "Your template has been created.", "success");
            setTemplate(selectedCheckboxValues);
            setIsDirty(false);
        }
    };

    if (!template) {
        return <Text>Loading...</Text>;
    }

    const handleCheckboxGroupChange = (selectedValues, e) => {
        let selectedValuesCopy = [...selectedCheckboxValues];
        if (e.currentTarget.checked) {
            selectedValuesCopy.push(e.currentTarget.value);
        } else {
            const index = selectedValuesCopy.indexOf(e.currentTarget.value);
            selectedValuesCopy.splice(index, 1);
        }
        selectedValues = [...new Set(selectedValuesCopy)].sort()
        setSelectedCheckboxValues(selectedValues);
        setIsDirty(!isEqual(selectedValues, template));
      }

    return (
        <Box sx={{width:'-webkit-fill-available', px: [3, 0, 0]}}>
            <Box display="grid" sx={{gap: 3}}>
                <CheckboxGroup onChange={handleCheckboxGroupChange}>
            {Object.keys(possibleValues).map((value) => (
                  <FormControl key={value}>
                    <Checkbox value={value} checked={selectedCheckboxValues.includes(value) ?? false} />
                    <FormControl.Label>{ possibleValues[value] }</FormControl.Label>
                  </FormControl>
            ))}
            </CheckboxGroup>
                </Box>
            <Box display="flex" sx={{gap: 2, mt:3, justifyContent:'end'}}>
                <Button mr={4} disabled={!isDirty} onClick={handleReset}>
                    Cancel
                </Button>
                <Button disabled={!isDirty} onClick={handleSave} variant='primary'>
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default TemplateEditor;
