import React, { useState, useEffect } from 'react';

const PrivacyPolicy = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('https://www.iubenda.com/api/privacy-policy/12613520')
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(data => setHtmlContent(data.content ?? ""))
      .catch(error => console.error('Fetch error:', error));
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default PrivacyPolicy;

