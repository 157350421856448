import React from "react";
import { Box, Button, Heading, Text } from "@primer/react";
import { useLocation } from "wouter";
import { ArrowLeftIcon } from "@primer/octicons-react";

const Container = ({ children, sx, heading, backButton }) => {
    const [, setLocation] = useLocation();
    return (
        <Box
            sx={{
                maxWidth: "360px",
                width: "100%",
                margin: "0 auto",
                textAlign: ['center', 'initial'],
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                ...sx,
            }}
        >
            {
                /* eslint-disable-next-line no-restricted-globals */
                backButton && <Box sx={{ml:-3}}><Button leadingVisual={ArrowLeftIcon} variant="invisible" onClick={() => typeof history !== "undefined" ? history.back() : setLocation(backButton)} sx={{fontSize:2, fontWeight:600}}>Back</Button></Box>
            }
            {
                heading &&
                <Box as="h2" sx={{ mb: 3 }}>{heading}</Box>
            }
            {children}
        </Box>
    );
}

export default Container;