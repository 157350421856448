import React from 'react';
import { format, isToday, isYesterday } from 'date-fns';

const StravaDate = ({ date }) => {
    const dateObj = new Date(date);
    let formattedDate;

    if (isToday(dateObj)) {
        formattedDate = `Today at ${format(dateObj, 'h:mm a')}`;
    } else if (isYesterday(dateObj)) {
        formattedDate = `Yesterday at ${format(dateObj, 'h:mm a')}`;
    } else {
        formattedDate = format(dateObj, 'MMMM d, yyyy \'at\' h:mm a');
    }

    return <span>{formattedDate}</span>;
};

export default StravaDate;
