import React, { useState } from 'react';
import { ouraAuthClient } from '../ouraAuthClient';
import { Button, Box } from "@primer/react";

function ConnectOuraButton() {
    const [isLoading, setIsLoading] = useState(false);
  
    const handleConnect = async () => {
        setIsLoading(true);
        window.location.href = ouraAuthClient.getAuthUrl();
    };

    return (
            <Button onClick={handleConnect} disabled={isLoading} variant='default' size='large' sx={{fontWeight:600}}>
                {isLoading ? 'Loading...' : 'Connect with Oura'}
            </Button>
    );
}

export default ConnectOuraButton;