import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import ConnectStravaButton from "./ConnectStravaButton";
import ConnectOuraButton from "./ConnectOuraButton";
import { Box, Spinner, Text, useColorSchemeVar } from "@primer/react";
import StravaUserInfo from "./StavaUserInfo";
import OuraUserInfo from "./OuraUserInfo";
import TemplateEditor from "./TemplateEditor";
import OuraLatestActivity from "./OuraLatestActivity";
import StravaLatestActivity from "./StravaLatestActivity";
import StravaIcon from "./StravaIcon";
import OuraIcon from "./OuraIcon";
import StravaUnprocessedActivities from "./StravaUnprocessedAcivities";
import { ouraColorScheme } from "../_shared/Backgrounds";
import { useConnectionStore } from "../store/Store";

function Connections({ session, messages, setMessages }) {

  const ouraBackground = useColorSchemeVar(ouraColorScheme);
  const loading = useConnectionStore((state) => state.loading);
  const stravaUser = useConnectionStore((state) => state.strava);
  const ouraUser = useConnectionStore((state) => state.oura);


  return (

    <>
      {loading ? (
        <Box
          sx={{
            mx: [0, 0, 6],
            my: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            minHeight: "75vh",
          }}
        >
          <Spinner size="large" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              mx: [0, 0, 2],
              my: [2, 2, 0],
                width: ["100%", 300, 300],
                flexDirection:"column",
                alignItems:"baseline"
              }}
              
          >
            <Box as="legend">
                <Box as="h4">
                  <Text>Your Oura Ring</Text>
              </Box>
            </Box>
            {ouraUser ? (
               <Box sx={{width:'-webkit-fill-available'}}>
                <Box
                  sx={{
                    p: 3,
                    borderWidth: 1,
                    borderStyle: "none",
                    borderColor: "border.default",
                    borderRadius: 5,
                    minWidth: 250,
                    minHeight: 45,
                    display: "flex",
                    alignItems: "center",
                    background: ouraBackground,
                    //color:"#000"
                  }}
                >
                  <OuraUserInfo  />
                </Box>
                <OuraLatestActivity session={session} />
              </Box>
            ) : (
              <Box
                sx={{
                  //mx: [6, 0, 0],
                  //my: [2, 2, 0],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: ["center", "center", "flex-start"],
                      py: [2, 0, 0],
                      width: "100%"
                }}
                  >
                    <Box
            sx={{
              display: "flex",
              gap: [1],
              alignItems: "center",
                              lineHeight: "1.2em",
              width: "100%",
            }}
          >
            <Box sx={{ mx: 2, display: "flex" }}>
              <OuraIcon size={38} />
            </Box>
                <ConnectOuraButton />
                      </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              mx: [0, 0, 2],
              my: [2, 2, 0],
                width: ["100%", 300, 300],
                flexDirection:"column",
                alignItems:"baseline"
            }}
          >
            <Box as="legend">
              <Text as="h4">Your Strava Account</Text>
            </Box>
            {stravaUser ? (
              <Box sx={{width:'-webkit-fill-available'}}>
                <Box
                  sx={{
                    p: 3,
                    borderWidth: 1,
                    borderStyle: "none",
                    borderColor: "border.solid",
                    borderRadius: 5,
                    minWidth: 250,
                    minHeight: 45,
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "canvas.subtle",
                  }}
                >
                  <StravaUserInfo stravaUser={stravaUser} />
                </Box>
                  <StravaLatestActivity stravaUser={stravaUser} />
                  <StravaUnprocessedActivities stravaUser={stravaUser} />
              </Box>
            ) : (
              <Box
                sx={{
                  //mx: [6, 0, 0],
                  //my: [2, 2, 0],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: ["center", "center", "flex-start"],
                  py: [2,0,0],
                }}
                  >
                    <Box
            sx={{
              display: "flex",
              gap: [1],
              alignItems: "center",
                              lineHeight: "1.2em",
              width: "100%",
            }}
          >
            <Box sx={{ mx: 2, display: "flex" }}>
              <StravaIcon size={38} />
            </Box>
                <ConnectStravaButton />
                      </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              //flexBasis: "100%",
              mx: [0, 0, 2],
              my: [2, 2, 0],
                width: ["100%", 300, 300],
                flexDirection:"column",
                alignItems:"baseline"
            }}
            >
              <Box as="legend">
                  <Text as="h4">Your Metrics</Text>
                </Box>
            {stravaUser && ouraUser ? (
               <Box sx={{width:'-webkit-fill-available'}}>
                
                <Box
                  sx={{
                    flexBasis: "100%",
                    display: "flex",
                    justifyContent: "center",
                      alignItems: "center",
                      pb: 3,
                  }}
                >
                  <TemplateEditor
                    session={session}
                    messages={messages}
                    setMessages={setMessages}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    backgroundColor: "accent.subtle",
                    color: "accent.emphasis",
                    borderRadius: 5,
                    borderWidth: 1,
                    borderStyle: "none",
                  }}
                >
                  <Box mb="2">
                  After linking your Oura and Strava accounts, you can choose the metrics you want to synchronize through ringsync. The available metrics include Readiness, Average Heart Rate Variability (HRV), Average Heart Rate (HR), Resting Heart Rate (RHR), and Sleep Duration.
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
{/* </Box>*/ }
    </>
  );
}

export default Connections;
