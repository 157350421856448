import * as React from "react";
const OuraIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 124}
    height={props.size || 124}
    viewBox="0 0 133 133"
  >
    <rect width="100%" height="100%" fill={props.fill || "#000"} />
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M66.48 42.976c16.561.018 29.982 13.449 30 30.022 0 16.58-13.431 30.022-30 30.022-16.569 0-30-13.441-30-30.022 0-16.58 13.431-30.022 30-30.022Zm0 6.477c-12.994 0-23.528 10.541-23.528 23.545 0 13.004 10.534 23.545 23.528 23.545 12.988-.014 23.513-10.547 23.528-23.545 0-13.004-10.534-23.545-23.528-23.545Zm15-19.433v6.477h-30V30.02h30Z"
    />
  </svg>
);
export default OuraIcon;
