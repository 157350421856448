//import columnNames from '../data/columnNames.json';
import columnNames from "../data/columnNames.json";

type ColumnInfo = {
    displayName: string,
    abbreviation: string,
    origin: string,
    generated: boolean
};

const displayName = (columnName: string): ColumnInfo => {
    let columnInfo = columnNames[columnName];
    
    // If the column name is found in the JSON file
    if (columnInfo) {
        return { ...columnInfo, generated: false };
    }

    // Generate display name and abbreviation if not found
    const words = columnName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const displayName = words.join(' ');
    let abbreviation = words.length === 1 ? words[0].substr(0, 3) : words.map(w => w[0]).join('');

    // Determine the origin based on the column name
    const origin = columnName.includes('strava') ? 'strava' : 'oura';

    return { displayName, abbreviation, origin, generated: true };
};

export default displayName;
