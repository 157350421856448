import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Label,
  Text,
  Avatar,
  useColorSchemeVar,
  Spinner,
  Button,
} from "@primer/react";
import { AlertIcon } from "@primer/octicons-react";
import Container from "../elements/Container";
import { Helmet } from "react-helmet";
import md5 from "md5";
import { supabase } from "../supabaseClient";
import OuraIcon from "../elements/OuraIcon";
import StravaIcon from "../elements/StravaIcon";
import ConnectOuraButton from "../elements/ConnectOuraButton";
import ConnectStravaButton from "../elements/ConnectStravaButton"; 
import { useLocation } from "wouter";
import ConfirmationBox from "../elements/ConfirmationBox";
import { useConnectionStore } from "../store/Store";


const Profile = ({ session }) => {
  const loading = useConnectionStore((state) => state.loading);
  const stravaUser = useConnectionStore((state) => state.strava);
  const ouraUser = useConnectionStore((state) => state.oura);
  const [, setLocation] = useLocation();

  const { user } = session;
  const name = user.user_metadata.full_name || user.email;
  const email_md5 = md5(user.email.toLowerCase().trim());
  const picture =
    user.user_metadata.avatar_url ??
    `https://www.gravatar.com/avatar/${email_md5}?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${name}/128`;


  const handleOuraDisconnect = async () => {
    console.log("Disconnecting Oura", ouraUser.id);
    const { data: deleteData, error: deleteError } = await supabase
            .from("oura_tokens").delete().eq("user_id", user.id).single();
          if (deleteError) {  
            console.log("oura_tokens", deleteError);
          } else {
              console.log(`deleted ${user.id} from oura_tokens`, deleteData); 
          }
    //setHasAuthorizedOura(false);
    useConnectionStore.setState({ oura: null });
  };
    
  const handleStravaDisconnect = async () => {
    console.log("Disconnecting Strava", stravaUser.id);
    const { data: deleteData, error: deleteError } = await supabase
            .from("strava_tokens").delete().eq("user_id", user.id).single();
          if (deleteError) {  
            console.log("strava_tokens", deleteError);
          } else {
              console.log(`deleted ${user.id} from strava_tokens`, deleteData); 
          }
    //setHasAuthorizedStrava(false);
    //stravaUser(null);
    useConnectionStore.setState({ strava: null });
  };

  const handleDeleteAccount = async () => {
    console.log("Deleting account", user.id);
    try {
      useConnectionStore.setState({ loading: true });
      if (!user) throw new Error('No user')
      await supabase.functions.invoke('user-self-delete')
    } catch (error) {
      console.log(error)
    } finally {
      useConnectionStore.setState({ loading: false });
      handleLogout();
    }    
  }


  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
    setLocation("/");
  };
    
    const Divider = () => (
        <Box
            sx={{
                height: "1px",
                backgroundColor: "border.default",
                my: 2,
                width: "100%"
            }}
        ></Box>
    )

  return (
    <Container
      heading={"Profile"}
      sx={{ maxWidth: 600, textAlign: "left", mb: 4 }}
      backButton="/dashboard"
    >
      <Helmet>
        <title>Profile | ringsync</title>
      </Helmet>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <Spinner size="large" />{" "}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: [2,3],
              alignItems: "center",
                lineHeight: "1.2em",
            width: "100%",
            }}
            >
              <Box sx={{ width: "54px", display: "flex", justifyContent: "space-around" }}>
                <Avatar src={picture} size={48} />
                </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                  justifyContent: "start",
                flex: 1,
              }}
            >
              <Box sx={{ fontSize: 2, fontWeight: 600 }}>{name}</Box>
              {user.email !== name && (
                <Box sx={{ fontWeight: 400, color: "fg.muted" }}>
                  {user.email}
                </Box>
              )}
              </Box>
              <Box sx={{ display: ['none', 'inherit'] }}>
              <Button
                  onClick={handleLogout}
                  variant="invisible"
                sx={{ fontWeight: 600 }}
                >
                  Sign out
                </Button>
                </Box>
                      </Box>
                      

                      <Divider />
                      <Box as="h4" sx={{ m: 0 }}>Subscription</Box>
                      <Box
            sx={{
              display: "flex",
              gap: [2,3],
              alignItems: "center",
                              lineHeight: "1.2em",
                              width: "100%",
              
            }}
            >
              {user.app_metadata.subscription === 'pro' ? 
                <>
              <Box sx={{ width: "54px", display: "flex", justifyContent: "space-around" }}>
                <Label size="large" variant="success">Pro</Label>
                  </Box>
                  <Box sx={{ flex: 1, display: 'flex' }}>
                    <Box sx={{ fontWeight: 600, color:'success.fg' }}>Pro Plan</Box>
                  </Box>
                  <Box><Button variant="invisible" sx={{ fontWeight: 600 }} as="a" href={"https://billing.stripe.com/p/login/cN216W8pz2tI09O5kk?prefilled_email=" + user.email}>Manage</Button></Box>
                </>
                :
                <>
                  <Box sx={{ width: "54px", display: "flex", justifyContent: "space-around" }}>
                <Label size="large" >Free</Label>
                  </Box>
                  <Box sx={{ flex: 1, display: 'flex', flexDirection:'column' }}>
                    <Box sx={{ fontWeight: 600, }}>Free Plan</Box>
                    <Box sx={{ fontWeight: 400, color: "fg.muted", display:['none', 'inherit'] }}>Upgrade for more metrics, charts and history</Box>
                  </Box>
                  <Box><Button variant="primary" disabled size="large" onClick={()=>setLocation('/subscribe')}>Upgrade</Button></Box>
                </>
          }
          </Box>
                      <Divider />
                      <Box as="h4" sx={{m:0}}>Connections</Box>
          <Box
            sx={{
              display: "flex",
              gap: [2,3],
              alignItems: "center",
                              lineHeight: "1.2em",
                              width: "100%",
              
            }}
          >
            <Box sx={{ mx: 2, display: "flex" }}>
              <OuraIcon size={38} />
            </Box>

            {ouraUser ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    flex: 1,
                  }}
                >
                  <Box sx={{ fontSize: 2, fontWeight: 600 }}>Connected</Box>
                  <Box sx={{ fontWeight: 400, color: "fg.muted", display:['none','inherit']}}>
                    {ouraUser.email}
                  </Box>
                </Box>
                <Button
                  onClick={handleOuraDisconnect}
                                      variant="invisible"
                                      sx={{fontWeight: 600}}
                >
                  Remove
                </Button>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    flexDirection: "column",
                    justifyContent: "start",
                                              flex: 1,
                                              display: ['none', 'flex', 'flex'],
                  }}
                >
                  <Box>Not connected</Box>
                </Box>
                <ConnectOuraButton />
              </>
            )}
                      </Box>
                      

                      <Box
            sx={{
              display: "flex",
              gap: [2,3],
              alignItems: "center",
                              lineHeight: "1.2em",
              width: "100%",
            }}
          >
            <Box sx={{ mx: 2, display: "flex" }}>
              <StravaIcon size={38} />
            </Box>

            {stravaUser ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    flex: 1,
                  }}
                >
                  <Box sx={{ fontSize: 2, fontWeight: 600}}>Connected</Box>
                  <Box sx={{ fontWeight: 400, color: "fg.muted", display:['none', 'inherit'] }}>
                  {stravaUser.firstname} {stravaUser.lastname}
                  </Box>
                </Box>
                <Button
                  onClick={handleStravaDisconnect}
                  variant="invisible"
                  sx={{fontWeight: 600}}
                >
                  Remove
                </Button>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: ['none', 'flex', 'flex'],
                    flexDirection: "column",
                    justifyContent: "start",
                    flex: 1,
                  }}
                >
                  <Box>Not connected</Box>
                </Box>
                <ConnectStravaButton />
              </>
            )}
            </Box>
            
            <Divider />
            <Box as="h4" sx={{ m: 0 }}>Danger Zone</Box>
            <Box sx={{ color: 'danger.fg', display: "flex", gap: 2, alignItems:'center' }}>
              <AlertIcon size={16} />
              Irreversible and destructive actions
            </Box>
            <Box as="h4" sx={{ m: 0 }}>Delete Account</Box>
            <ConfirmationBox session={session} callback={handleDeleteAccount} text={"This cannot be undone: Once your account is deleted, all associated data is lost as well. Your activity descriptions will keep the Oura data though. You will be asked for confirmation in the next step."} buttonLabel="Delete account" />
                      
        </Box>
      )}
    </Container>
  );
};

export default Profile;
