import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Brush,
  AreaChart,
  Area,
  Label,
  LabelList,
} from "recharts";

import { themeGet, Box, useTheme } from "@primer/react";
import { useActivityStore, useSleepStore } from "../store/Store";
import { format } from "date-fns";
import { calculateEMA } from "../_shared/Statistics";
import displayName from "../_shared/DisplayName";

const DashboardChart = ({
  service,
  metric,
  emaPeriod,
  height,
  line,
  filter,
}) => {
  let activityData = useActivityStore((state) => state.activities);
  console.log(activityData);
  let sleepData = useSleepStore((state) => state.sleepData);
  const theme = useTheme();
  const fgSubtle = themeGet("colors.fg.subtle")(theme);
  const fg = themeGet("colors.fg.default")(theme);
  const fgMuted = themeGet("colors.fg.muted")(theme);
  const fgAccent = themeGet("colors.accent.fg")(theme);
  const accentMuted = themeGet("colors.accent.muted")(theme);
  const fgDanger = themeGet("colors.danger.fg")(theme);
  const dangerMuted = themeGet("colors.danger.muted")(theme);
  const stroke =
    line && line.color ? themeGet(line.color)(theme) || line.color : fgAccent;
  const grid = themeGet("colors.canvas.subtle")(theme);

  let dataRaw =
    service === "strava"
      ? activityData.sort(
          (a, b) => new Date(a.start_date) - new Date(b.start_date)
        )
      : sleepData.filter((d) => d.type === "long_sleep").sort((a, b) => new Date(a.day) - new Date(b.day));
  if (filter) {
    dataRaw = dataRaw.filter((d) => filter(d));
  }
  const values = dataRaw.map((d) => d[metric]);
    const ema = emaPeriod ? calculateEMA(values, emaPeriod) : null;
    const name = displayName(metric).displayName;

  let data = dataRaw.map((d, i) => {
    let day = format(new Date(d.day ?? d.start_date_local), "MM/dd");
    return { day: day, [name]: d[metric], "Average": ema ? ema[i] : null };
  });
  console.log(data);

  //const values = data.map((d) => d[metric]);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const range = max - min;
  const domainMin = Math.max(0, Math.floor(min - range * 0.1));
  const domainMax = Math.ceil(max + range * 0.1);
  const domain = [
    Math.floor(domainMin / 5) * 5,
    Math.ceil(domainMax / 5) * 5,
  ];
  console.log(domain);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart data={data}>
        <XAxis
          dataKey="day"
          stroke={fgSubtle}
          label={{ fill: fgSubtle }}
          tick={{ fill: fgSubtle, fontSize: "small" }}
          tickLine={{ stroke: fgSubtle }}
        />
        <YAxis
          type="number"
          domain={domain}
          allowDecimals={false}
          stroke={fgSubtle}
          label={{ fill: fgSubtle }}
          tick={{ fill: fgSubtle, fontSize: "small" }}
          tickLine={{ stroke: fgSubtle }}
          tickMargin={5}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: grid,
            border: "none",
            borderRadius: 5,
          }}
        />
        <CartesianGrid stroke={grid} />
        <Line
          type="monotone"
          dataKey={name}
          stroke={stroke}
          strokeWidth={2}
          connectNulls={true}
          {...line}
        />
        {ema && (
          <Line
            type="monotone"
            dataKey="Average"
            stroke={fgDanger}
            strokeWidth={2}
            connectNulls={true}
            {...line}
          />
        )}
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashboardChart;
