import React from "react";
import { Box, Label, Button, Heading } from "@primer/react";
import { CheckCircleFillIcon } from "@primer/octicons-react";

const PricingTableColumn = ({
  title,
  description,
  price,
  features,
  imageUrl,
  highlight,
  button,
  session,
}) => (
  <Box
    sx={{
      borderWidth: 1,
      borderRadius: 5,
      p: 5,
      maxWidth: 310,
      minWidth: 280,
      textAlign: "left",
      gap: 3,
      display: "flex",
      flexDirection: "column",
      backgroundColor: highlight ? "canvas.inset" : "canvas.default",
      borderColor: highlight ? "fg.muted" : "transparent",
      borderStyle: "solid",
      flex: 1,
    }}
  >
    {imageUrl && <img src={imageUrl} alt={title} />}
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Heading fontSize={1}>{title}</Heading>
      {highlight && (
        <Label sx={{ ml: 2 }} variant="accent">
          Most Popular
        </Label>
      )}
    </Box>
    <Box sx={{ color: "fg.muted", minHeight: "3.5em" }}>{description}</Box>
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box sx={{ fontWeight: 600, fontSize: 6 }}>
        {price.currency}
        {price.amount}
      </Box>
      {price.interval && (
        <Box
          sx={{
            display: "flex",
            fontSize: 1,
            color: "fg.subtle",
            ml: 2,
            alignSelf: "center",
            flexDirection: "column",
            lineHeight: "1.1em",
          }}
        >
          <Box>per</Box>
          <Box>{price.interval}</Box>
        </Box>
      )}
    </Box>
    <Button
      variant={button.variant ?? "primary"}
      size="large"
      sx={{ fontSize: 2, fontWeight: "500" }}
      as="a"
      href={button.link + (session ? `?prefilled_email=${session.user.email}` : "")}
    >
      {button.text}
    </Button>
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
      {features.map((feature, index) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <CheckCircleFillIcon size={14} sx={{ color: "muted.fg" }} />
          <Box>{feature}</Box>
        </Box>
      ))}
    </Box>
  </Box>
);

export default PricingTableColumn;
