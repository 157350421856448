import React, { useEffect } from "react";
import { Flash } from "@primer/react";

function MessageStack({ messages, clearMessages }) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      clearMessages();
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [messages, clearMessages]);

  return (
    <>
      {messages.map(({ message, variant }, index) => (
        <Flash key={index} variant={variant}>
          {message}
        </Flash>
      ))}
    </>
  );
}

export default MessageStack;